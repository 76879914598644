<template>
	<pop-up-window
		:show="showPopUp"
		:showClose="false"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Identifier registered on Peppol</template>
		<template #content>
			<p class="error">!IMPORTANT! It might take a few minutes before it is processed</p>
			<div>
				<div ref="peppolParticipantsTable"></div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md bg-theme-1 text-white mr-2 mt-2" @click="$emit('close')">
						Close
					</button>
				</div>
			</div>
		</template>

	</pop-up-window>
</template>

<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import PopUpWindow from "@/components/PopUp.vue";

export default {
	components: {PopUpWindow},
	emits: ['close'],
	props: {
		peppolIdentifiers: Array,
		showPopUp: Boolean
	},
	data() {
		return {
			dnsRecords: null,
			table: null,
			tableData: null,
			isResizing: false,
			copiedBool: false,
			columns: [
				{
					title: "",
					field: "registeredOnNymus",
					hozAlign: "center",
					width: '10%',
					headerSort: false,
					formatter(cell) {
						if (cell.getData().registeredOnNymus) {
							return `<div  class="font-medium whitespace-nowrap salary billing">
                                  <span class="material-icons-outlined" style="color: #91c714">check_circle</span>
                              </div>`
						} else {
							return `<div  class="font-medium whitespace-nowrap salary billing">
                                   <span class="material-icons-outlined" style="color: #d32929">highlight_off</span>
                              </div>`
						}

					}
				},
				{
					title: "Identifier",
					field: "identifier",
					width: '20%',
					hozAlign: "center",
					headerSort: false
				},
				{
					title: "Service description",
					field: "serviceDescription",
					hozAlign: "left",
					headerSort: false,
				}
			]
		}
	},
	async mounted() {
		await this.getSmpRecords()
	}
	,
	methods: {
		async getSmpRecords() {
			let payload = {
				identifiers: []
			}
			this.peppolIdentifiers.forEach(identifier => payload.identifiers.push(identifier.identifier.toLowerCase()))
			await this.$store.dispatch('peppol/getSmpParticipants', payload)

			this.tableData = []
			this.peppolIdentifiers.forEach(identifier => {
				let smpRecords = this.$store.state.smpParticipants.filter(smpP => identifier.identifier.toLowerCase() === smpP.identifier)
				let registeredOnNymus= true
				let services = []
				if (smpRecords.length > 0) {
					smpRecords.forEach(record => {
						if (services.find(s => s.trim() === record.serviceDescription) === undefined) services.push(' ' + record.serviceDescription)
						if (!record.serviceDescription.toLowerCase().includes('nymus')) registeredOnNymus = false
					})
					services[0].replace(' ', '')
				} else {
					registeredOnNymus = false
					services.push('No Info')
				}
				this.tableData.push({
					identifier: identifier.identifier,
					serviceDescription: services,
					registeredOnNymus: registeredOnNymus
				})
			})

			await this.initTabulator()
		},
		async initTabulator() {
			try {
				this.table = await new Tabulator(this.$refs['peppolParticipantsTable'], {
					headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
					layout: "fitColumns",
					columnDefaults: {
						resizable: false,
					},
					autoResize: true,
					locale: false,

					resizableColumnFit: true,
					data: this.tableData,
					columns: this.columns
				})
				window.addEventListener('resize', () => {
					if (this.isResizing === false && this.table !== undefined) {
						this.isResizing = true
						this.table.setColumns(this.columns)
						this.isResizing = false
					}
				})
			} catch (error) {
				this.$store.commit('displayErrorAlertText', "Error: " + error.message, {root: true})
				this.$store.commit('displayErrorAlert', true, {root: true})
			}
		}
	}

}
</script>

<style scoped>
.not-verified {
	color: #d32929;
}

.verified {
	color: #91c714;
}
</style>