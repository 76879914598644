<template>
	<div>
		<div class="flex flex-row" style="margin-bottom: 10px">
			<p class="mr-2 font-bold">Peppol:</p>
			<ToggleSlide
				class="w-min"
				@update-value="peppol = !peppol"
				:bool="peppol"
			/>
		</div>
		<div v-if="peppol" class="ml-4">
			<button class="btn size-10" @click="addRecipient">
				+
			</button>
			<div v-for="(item, index) in peppolIdentifiers" :key="index">

				<div class="flex xl:flex-row w-full flex-col">
					<div class="flex flex-row w-2/3 items-center">
						<peppol-identifier-selector class="basis-full" v-model="peppolIdentifiers[index]"/>
						<button class="btn size-10 mt-6" @click="removeRecipient(index)" v-if="showRemoveRecipient">
							-
						</button>
					</div>

					<DropdownSelectAll
						class="xl:ml-4 xl:mt-6 lg:mt-2 w-1/3"
						:id="'documentTypes-' + index"
						:data="this.$store.state.standardDocumentTypeIdentifiers"
						:disabled="false"
						:no-select-all="true"
						:selected="peppolIdentifiers[index].documents"
						@update-value="updateDocuments($event, index)"
					/>
				</div>
			</div>

			<button
				id="setReporting"
				class="btn mr-4 mt-2 mb-4 text-white"
				:style="{backgroundColor: setColorSetButton}"
				@click="updatePeppol"
			>
				Set Peppol
			</button>
			<button
				id="setReporting"
				class="btn mt-2 mb-4 text-white"
				:style="{backgroundColor: this.colors.blue}"
				@click="showSmp = true"
			>
				getSmp
			</button>
		</div>
		<view-registered-on-peppol v-if="showSmp" :show-pop-up="showSmp" :peppol-identifiers="peppolIdentifiers"
								   @close="showSmp = false"/>
	</div>
</template>
<script>
import ToggleSlide from "@/components/ToggleSlide.vue";
import Dropdown from "@/components/Dropdown.vue";
import countryCodes from "@/countryCodes";
import PeppolIdentifierSelector from "@/components/AddLegalEntity/peppolIdentifierSelector.vue";
import ViewRegisteredOnPeppol from "@/components/AddLegalEntity/viewRegisteredOnPeppol.vue";
import DropdownSelectAll from "@/components/DropdownSelectAll.vue";
import VueSelect from "vue3-select";

export default {
	components: {VueSelect, DropdownSelectAll, ViewRegisteredOnPeppol, PeppolIdentifierSelector, Dropdown, ToggleSlide},
	props: ['colors', 'createdLeEntityNumber', 'createdLeName'],
	data() {
		return {
			colorSetButton: null,
			peppol: false,
			showSmp: false,
			peppolIdentifiers: [
				{
					identifier: '0208:' + this.createdLeEntityNumber,
					countryCode: 'BE',
					documents: ['INVOICE_RESPONSE_3_0']
				},
				{
					identifier: '9925:BE' + this.createdLeEntityNumber,
					countryCode: 'BE',
					documents: ['INVOICE_RESPONSE_3_0']
				}
			],
		}
	},
	computed: {
		showRemoveRecipient() {
			return this.peppolIdentifiers.length !== 1
		},
		setColorSetButton() {
			if (this.colorSetButton === null) return this.colors.blue
			else if (this.colorSetButton === 'success') return this.colors.success
			else return this.colors.error
		},
	},
	methods: {
		countryCodes() {
			return countryCodes
		},
		addRecipient() {
			this.peppolIdentifiers.push({identifier: '', countryCode: 'BE', documents: ['INVOICE_RESPONSE_3_0']})
		},
		removeRecipient(index) {
			this.peppolIdentifiers.splice(index, 1)
		},
		updateDocuments(data, index) {
			this.peppolIdentifiers[index].documents = []
			data.forEach(document => {
				if (document !== -1) {
					this.peppolIdentifiers[index].documents.push(this.$store.state.standardDocumentTypeIdentifiers[document].name)
				}
			})
		},
		registerIdentifier() {
			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				leId: this.createdLeEntityNumber,
				peppolParticipants: {
					peppolParticipants: {}
				}
			}
			this.peppolIdentifiers.forEach(peppolIdentifier => {
				payload.peppolParticipants.peppolParticipants[peppolIdentifier.identifier.toLowerCase()] = {
					countryCode: peppolIdentifier.countryCode,
					isReceiver: false,
					isSender: false,
					name: this.createdLeName
				}
			})

			this.$store.dispatch('peppol/addPeppolIdentifier', payload)
		},
		async updatePeppol() {
			this.colorSetButton = 'blue'
			let payload = {
				identifiers: []
			}

			try {
				this.peppolIdentifiers.forEach(identifier => payload.identifiers.push(identifier.identifier.toLowerCase()))
				await this.$store.dispatch('peppol/getSmpParticipants', payload)

				for (const identifier of this.peppolIdentifiers) {
					let smpRecords = this.$store.state.smpParticipants.filter(smpP => identifier.identifier === smpP.identifier)

					if (smpRecords.length === 0) {
						// Not registered
						let payload = {
							countryCode: identifier.countryCode,
							identifier: identifier.identifier.toLowerCase(),
							name: this.createdLeName,
							smpProvider: "SMP_NYMUS"
						}
						await this.$store.dispatch("peppol/createPeppolParticipant", payload)

					} else if (smpRecords[0].serviceDescription.toLowerCase() === 'hermes service') {
						let migrationKey = await this.$store.dispatch('peppol/getHermesMigrationKey', identifier.identifier)

						let payload = {
							identifier: identifier.identifier.toLowerCase(),
							migrationKey: migrationKey,
							smpProvider: "SMP_NYMUS",
							name: this.createdLeName,
							countryCode: identifier.countryCode
						}
						await this.$store.dispatch("peppol/migrateSmp", payload)
					}

					for (const document of identifier.documents) {
						let formData = {
							identifier: identifier.identifier.toLowerCase(),
							smpProvider: "SMP_NYMUS",
							documentTypeIdentifier: document
						}
						await this.$store.dispatch("peppol/createPeppolDocument", formData)
					}
				}

				this.registerIdentifier()
				this.colorSetButton = "success"
			} catch
				(error) {
				this.colorSetButton = 'error'
				this.$store.commit('displayErrorAlertText', "Error: " + error.message)
				this.$store.commit('displayErrorAlert', true)
			}
		}
	}
}
</script>