<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditExtraction }"
		class="box shadow-none col-span-6"
	>
		<div class="button-container add-new-template">
			<button
				class="btn m-5 bg-theme-1 shadow-md mr-2 text-white"
				@click="addExtractionTemplate">Add new template
			</button>
		</div>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 ingestion-content">
			<div class="flex flex-row flex-nowrap">
				<div
					class="flex flex-col w-1/3"
					v-if="this.$store.state.extractionTemplates.length <= 0 || this.$store.state.extractionTemplates[0] === undefined">
					No template yet
				</div>

				<div
					class="flex flex-col w-1/4"
					v-if="this.$store.state.extractionTemplates.length > 0 && this.$store.state.extractionTemplates[0] !== undefined ">
					<Dropdown
						:data="templateNames"
						@update-value="updateTemplateName($event)"
						:selected='this.$store.state.extractionTemplates[0]?.name'
					/>
					<div v-if=formData.legalEntityId>
						<div>
							<div class="one-line-grid mt-2">
								<p class="text-lg font-bold mr-2">General template info:</p>
								<div>
									<button
										class="btn py-0 shadow-md mb-2"
										@click="toggleFields('displayInfo')"
									>
										{{ displayText }}
									</button>
								</div>
							</div>
							<div class="displayInfo ml-4" v-if="displayInfo">
								<FileUpload
									class="mt-2"
									:disable=true
									:data="formData.pdfFile"
									:delSuffix="false"
									id="editPdfTemplateUploadPreview"
									title="pdf template:"
									accept=".pdf"
									:uploadIconNotDisplay=true
								/>
								<Field
									id="viewExtractionLocation"
									:disabled="true"
									title="Location:"
									type="text"
									class="mt-2"
									:modelValue="formData.location"
								/>
								<div class="mt-2 mb-5">
									<p class="font-bold form-label mb-0"> Legal entity: </p>
									<Dropdown
										class="mt-2"
										:data="legalEntities"
										:selected=selectedLe(formData.legalEntityId)
										:disabled=true
									/>
								</div>
								<div class="mt-4 move-address-location">
									<label class="font-bold form-label mb-0">
										Move address location:
									</label>
									<ToggleSlide
										:disabled=true
										:renew-bool="true"
										class="w-min"
										:bool="formData.moveAddressLocation"
									/>
								</div>
								<div class="mt-4 move-address-location">
									<label class="font-bold form-label mb-0">
										Use system date when no date was found:
									</label>
									<ToggleSlide
										:disabled=true
										class="w-min"
										:renew-bool="true"
										:bool="formData.useSystemDate"
									/>
								</div>
								<div class="mt-2">
									<p class="font-bold form-label mb-0">Default country: </p>
									<Dropdown
										class="mt-2"
										:data="defaultCountryOptions"
										:selected=formData.defaultCountry
										:disabled=true
									/>
								</div>
								<div class="mt-2">
									<p class="font-bold form-label mb-0">Paper format: </p>
									<Dropdown
										class="mt-2"
										:data="paperFormatOptions"
										:selected=formData.paperFormat
										:disabled=true
									/>
								</div>
							</div>
							<div>
								<div class="button-container mt-2">
									<p class="text-lg font-bold mr-2">
										Extraction Fields:
									</p>
									<div>
										<button
											@click="toggleFields('displayExtractionField')"
											class="btn py-0 shadow-md mb-2"
										>
											{{ toggleFieldsText }}
										</button>
									</div>
								</div>

								<div v-if="displayExtractionField">
									<Accordion
										@click="giveDraggableColor(index)"
										:class="{ 'bg-primary-4': selectedField === index }"
										class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header-view-mode"
										:key="item + index"
										v-for="(item, index) in formData.areas"
									>
										<template v-slot:title>
											<span class="font-semibold">{{ index }}</span>
										</template>
										<template v-slot:content>
											<div class="p-3 flex flex-col w-full">
												<div class="flex">
													<Field
														id="viewExtractionXCoord"
														title="X-Coord:"
														:disabled="true"
														:numberIntervals="0.5"
														type="number"
														v-model="item.x"
													/>
													<Field
														id="viewExtractionYCoord"
														title="Y-Coord:"
														:disabled="true"
														:numberIntervals="0.5"
														type="number"
														v-model="item.y"
													/>
													<Field
														id="viewExtractionWidth"
														title="Width:"
														:disabled="true"
														type="number"
														v-model="item.width"
													/>
													<Field
														id="viewExtractionHeight"
														title="Height:"
														:disabled="true"
														type="number"
														v-model="item.height"
													/>
												</div>

												<div v-if="item.navigationText">
													<div class="mb-3">
														<Field
															id="viewExtractionRegex"
															type="string"
															title="Navigation text:"
															v-model="item.navigationText"
															:disabled=true
														/>
													</div>
												</div>
												<div class="mt-4 default-date">
													<p class="font-bold form-label mb-0">
														Use system date as default date:
													</p>
													<ToggleSlide
														:disabled=true
														class="w-min"
														:id="'view' + index"
														:bool="item.useSystemDateAsDefaultDate ? item.useSystemDateAsDefaultDate : false"
													/>
												</div>
												<div v-if="item.defaultValue">
													<div class="mb-3">
														<Field
															id="viewDefaultValue"
															type="string"
															title="Default value:"
															v-model="item.defaultValue"
															:disabled=true
														/>
													</div>
												</div>
												<div v-if="item.dateFormatter">
													<div class="mb-3">
														<Field
															id="viewDateFormatter"
															type="string"
															title="Date formatter:"
															v-model="item.dateFormatter"
															:disabled=true
														/>
													</div>
												</div>
												<div class="mb-3" v-if="item.bigDecimalFormatter">
													<label class="font-bold form-label" v-if="item.bigDecimalFormatter">
														Big decimal formatter:
													</label>
													<div class="ml-4">
														<label class="font-bold form-label">Grouping separator:</label>
														<Dropdown
															:disabled=true
															:data="separatorOptions"
															:key="1"
															:selected="item.bigDecimalFormatter.groupingSeparator"
														/>
													</div>

													<div class="ml-4 mt-2">
														<label class="font-bold form-label">Decimal separator:</label>
														<Dropdown
															:disabled=true
															:data="separatorOptions"
															:key="2"
															:selected="item.bigDecimalFormatter.decimalSeparator"
														/>
													</div>
												</div>
												<div class="mb-3" v-if="item.additionalRules">
													<label class="font-bold form-label" v-if="item.additionalRules">
														Additional rules:
													</label>
													<div v-if="item.additionalRules.offset" class="ml-4">
														<label class="font-bold form-label">Offset:</label>
														<Dropdown
															:disabled=true
															:data="offsetOptions"
															:key="3"
															:selected="item.additionalRules.offset.toString()"
														/>
													</div>
												</div>
											</div>
										</template>
									</Accordion>
								</div>
							</div>
							<div class="table-field-container" v-if="formData.extractionTableInfo">
								<div>
									<div class="one-line-grid">
										<p class="text-lg font-bold mr-2">General table info:</p>
										<div>
											<button
												class="btn py-0 shadow-md mb-2"
												@click="toggleFields('displayInfoTable')"
											>
												{{ displayTextTable }}
											</button>
										</div>
									</div>
									<div v-if="displayInfoTable" class="ml-4">
										<Field
											:disabled="true"
											title="Table headers* (separate by comma):"
											type="text"
											v-model="formData.extractionTableInfo.tableHeaders"
										/>
										<Field
											:disabled="true"
											title="Table cutoff* (separate by comma):"
											type="text"
											v-model="formData.extractionTableInfo.tableCutoff"
										/>
										<Field
											title="Max description lines* (only values >=1):"
											:numberIntervals="1"
											type="number"
											v-model="formData.extractionTableInfo.maxDescriptionLines"
											:disabled="true"
										/>
										<div class="">
											<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
											<Dropdown
												:disabled="true"
												:data="documentFieldOptions"
												:selected="formData.extractionTableInfo.presentInAllRows"
											/>
										</div>
									</div>
								</div>
								<div class="button-container-template mt-2">
									<p class="text-lg font-bold mr-2">Extraction Table Fields:</p>
									<div>
										<button
											v-if="formData.extractionTableInfo"
											@click="toggleFields('displayExtractionTableField')"
											class="btn py-0 shadow-md mb-2"
										>
											{{ toggleExtractionTableFieldText }}
										</button>
									</div>
								</div>
								<div>
									<div v-if="displayExtractionTableField">
										<Accordion
											@click="giveDraggableColor(index)"
											:class="{ 'bg-primary-4': selectedField === index }"
											class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header-view-mode"
											:key="item + index"
											v-for="(item, index) in formData.extractionTableInfo.tableColumns"
										>
											<template v-slot:title>
												<span class="font-semibold">{{ index }}</span>
											</template>
											<template v-slot:content>
												<div class="flex">
													<Field
														title="X-Coord:"
														:numberIntervals="0.1"
														type="number"
														v-model="item.x"
													/>
													<Field
														:disabled="true"
														title="Y-Coord:"
														:numberIntervals="0.1"
														type="number"
														v-model="item.y"
													/>
												</div>
												<div class="flex">
													<Field
														:disabled="true"
														title="Width:"
														type="number"
														v-model="item.width"
													/>
													<Field
														:disabled="true"
														title="Height:"
														type="number"
														v-model="item.height"
													/>
												</div>
												<div>
													<Field
														:disabled="true"
														title="Default value:"
														type="text"
														v-model="item.defaultValue"
													/>
													<div class="mt-2" v-if="item.bigDecimalFormatter">
														<label class="font-bold form-label">
															Big decimal formatter:
														</label>
														<div>
															<div>
																<label class="font-bold form-label">Grouping
																	separator:</label>
																<Dropdown
																	:disabled="true"
																	:data="separatorOptions"
																	:key="3"
																	:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.groupingSeparator : 'No separator'"
																/>
															</div>
															<div>
																<label class="font-bold form-label">Decimal
																	separator:</label>
																<Dropdown
																	:disabled="true"
																	:data="separatorOptions"
																	:key="3"
																	:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.decimalSeparator : 'No separator'"
																/>
															</div>
														</div>
													</div>
												</div>
											</template>
										</Accordion>
									</div>
								</div>
							</div>
							<div class="table-field-container">
								<div v-if="formData.summaryTableInfo">
									<div class="one-line-grid">
										<p class="text-lg font-bold mr-2">General summary info:</p>
										<div>
											<button
												class="btn py-0 shadow-md mb-2"
												@click="toggleFields('displayInfoSummaryTable')"
											>
												{{ displayTextSummaryTable }}
											</button>
										</div>
									</div>
									<div v-if="displayInfoSummaryTable" class="ml-4">
										<Field
											:disabled="true"
											title="Table headers*:"
											type="text"
											v-model="formData.summaryTableInfo.tableHeaders"
										/>
										<Field
											:disabled="true"
											title="Table cutoff*:"
											type="text"
											v-model="formData.summaryTableInfo.tableCutoff"
										/>
										<Field
											:disabled="true"
											title="Max description lines* (only values >=1):"
											:numberIntervals="1"
											type="number"
											v-model.number="formData.summaryTableInfo.maxDescriptionLines"
										/>
										<div class="">
											<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
											<Dropdown
												:data="documentSummaryFieldOptions"
												:selected="formData.summaryTableInfo.presentInAllRows ? formData.summaryTableInfo.presentInAllRows : documentSummaryFieldOptions[0].name"
											/>
										</div>
										<div>
											<label class="font-bold form-label mt-2">Offset:</label>
											<Dropdown
												:data="offsetOptions"
												:selected="formData.summaryTableInfo.offset? formData.summaryTableInfo.offset : 'No offset'"
											/>
										</div>
									</div>
								</div>
								<div class="button-container mt-2" v-if="formData.summaryTableInfo">
									<p class="text-lg font-bold mr-2">Summary Fields:</p>
									<div>
										<button
											v-if="formData.summaryTableInfo"
											@click="toggleFields('displayExtractionSummaryTableField')"
											class="btn py-0 shadow-md mb-2"
										>
											{{ toggleExtractionSummaryTableFieldText }}
										</button>
									</div>
								</div>
								<div v-if="formData.summaryTableInfo && displayExtractionSummaryTableField">
									<div>
										<Accordion
											:trigger="triggered"
											@click="giveDraggableColor(index)"
											:class="{ 'bg-primary-4': selectedField === index}"
											class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
											:key="item + index"
											v-for="(item, index) in formData.summaryTableInfo.tableColumns"
										>
											<template v-slot:title>
												<span class="font-semibold">{{ index }}</span>
											</template>
											<template v-slot:content>
												<div class="flex">
													<Field
														title="X-Coord:"
														:numberIntervals="0.1"
														type="number"
														v-model="item.x"
													/>
													<Field
														title="Y-Coord:"
														:numberIntervals="0.1"
														type="number"
														v-model="item.y"
													/>
												</div>
												<div class="flex">
													<Field
														title="Width:"
														type="number"
														v-model="item.width"
													/>
													<Field
														title="Height:"
														type="number"
														v-model="item.height"
													/>
												</div>
												<div>
													<Field
														title="Default value:"
														type="text"
														v-model="item.defaultValue"
													/>
													<div class="mt-2">
														<label
															class="font-bold form-label"
															v-if="item.bigDecimalFormatter"
														>
															Big decimal formatter:
														</label>
														<div class="ml-4 mt-2">
															<div>
																<label class="font-bold form-label">
																	Grouping separator:
																</label>
																<Dropdown
																	:data="separatorOptions"
																	:key="3"
																	:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.groupingSeparator : 'No separator'"
																/>
															</div>
															<div class="mt-2">
																<label class="font-bold form-label">Decimal
																	separator:</label>
																<Dropdown
																	:data="separatorOptions"
																	:key="3"
																	:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.decimalSeparator : 'No separator'"
																/>
															</div>
														</div>
													</div>
												</div>
											</template>
										</Accordion>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-3/4 mx-auto" >
					<div v-if="!displayTemplate && this.formData.length !== 0" class="no-pdf">
						<p>No pdf yet</p>
					</div>
					<div v-if="displayTemplate" ref="boundary" class="mx-auto relative border-4">
						<div
							:key="item + item.field + index"
							v-for="(item, index) in formData.areas"
							:ref="index"
							:class="{'green': selectedField === index, 'z-index-100': selectedField === index}"
							class="makeSquare cursor-move absolute border z-20"
							:style="{bottom: item.y + '%', left: item.x + '%', width: item.width + '%', height: item.height + '%'}"
						>
						</div>
						<div v-if="formData.extractionTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.extractionTableInfo.tableColumns"
								:ref="index"
								:class="{'green': selectedField === index, 'z-index-100': selectedField === index}"
								class="makeSquare cursor-move absolute border z-20"
								:style="{bottom: item.y + '%', left: item.x + '%', width: item.width + '%', height: item.height + '%'}"
							>
							</div>
						</div>
						<div v-if="formData.summaryTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.summaryTableInfo.tableColumns"
								:ref="index"
								:class="{'green': selectedField === index,'z-index-100': selectedField === index}"
								class="makeSquare cursor-move absolute border z-20"
								:style="{bottom: item.y + '%', left: item.x + '%', width: item.width + '%', height: item.height + '%'}"
							>
							</div>
						</div>
						<div ref="pdfContainer" v-if="formData.pdfFile !== undefined">
							<VuePdf
								class="w-full"
								:scale="1.25"
								:src="formData.pdfFile"
								:page="1"
							/>
							<button class="btn py-0 shadow-md"
									style="z-index: 50; position: absolute; right: 6px; top: 5px; padding: 0; background-color: #e2e8f0 "
									@click="download">
								<span class="material-icons" style="font-size: 20px">file_download</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import Field from "../Field.vue"
import Accordion from "../Accordion.vue"
import Dropdown from "../Dropdown.vue"
import FileUpload from "../FileUpload.vue"
import ToggleSlide from "../ToggleSlide.vue"
import {VuePdf} from "vue3-pdfjs"

export default {
	name: "ViewExtraction",
	components: {VuePdf, Field, Dropdown, Accordion, FileUpload, ToggleSlide},
	props: {
		fieldTypeData: Array,
		extractionDataType: Array,
	},
	mounted() {
		console.log(this.formData?.pdfFile)
		this.getExtractionTemplateIds()
		// this.getExtractionTemplate()
	},
	data() {
		return {
			triggered: false,
			displayExtractionSummaryTableField: true,
			documentSummaryFieldOptions: this.$store.state.tableSummaryFieldTypeData,
			displayInfoSummaryTable: false,
			displayExtractionField: true,
			displayExtractionTableField: true,
			documentFieldOptions: this.$store.state.tableFieldTypeData,
			displayInfoTable: false,
			displayInfo: false,
			displayComponent: false,
			manipulatedFields: [...this.fieldTypeData],
			showConfirm: false,
			cursorStateBool: false,
			currentDelItem: {},
			selectedField: "",
			isDragging: false,
			formData: [],
			activeTemplateIndex: 0,
			extractionTemplateIds: this.$store.state.extractionTemplateIds,
			templateArray: [],
			paperFormatOptions: [
				{id: 0, name: "A4"},
				{id: 1, name: "US_LETTER"},
			],
			separatorOptions: [
				{id: 0, name: "COMMA"},
				{id: 1, name: "DOT"},
				{id: 2, name: "SPACE"},
				{id: 3, name: "No separator"},
			],
			offsetOptions: [
				{id: 0, name: "No offset"},
				{id: 1, name: "-2"},
				{id: 2, name: "-1"},
				{id: 3, name: "1"},
				{id: 4, name: "2"},
			],
			defaultCountryOptions: [
				{id: 0, name: "BE"},
				{id: 1, name: "FR"},
				{id: 2, name: "NL"},
				{id: 3, name: "No default country"}
			]
		}
	},
	watch: {
		"$store.state.extractionTemplateIds": {
			handler() {
				this.getExtractionTemplate();
			},
			deep: true
		},
		"$store.state.cancelEditExtraction": {
			handler(newVal) {
				if (newVal) {
					this.getExtractionTemplate();
					this.$store.commit('cancelEditExtraction', false)
				}
			}
		},
	},
	computed: {
		toggleExtractionSummaryTableFieldText() {
			if (!this.displayExtractionSummaryTableField) {
				return "Show"
			}
			return "Hide"
		},
		// selectedOption(){
		//
		// 	return this.offsetOptions.indexOf(o => o.name === item.additionalRules.offset)
		// },
		displayTextSummaryTable() {
			if (!this.displayInfoSummaryTable) {
				return "Show"
			}
			return "Hide"
		},
		toggleFieldsText() {
			if (!this.displayExtractionField) {
				return "Show"
			}
			return "Hide"
		},
		toggleExtractionTableFieldText() {
			if (!this.displayExtractionTableField) {
				return "Show"
			}
			return "Hide"
		},
		displayText() {
			if (!this.displayInfo) {
				return "Show"
			}
			return "Hide"
		},
		displayTextTable() {
			if (!this.displayInfoTable) {
				return "Show"
			}
			return "Hide"
		},
		displayTemplate() {
			return this.formData.pdfFile
		},
		legalEntities() {
			let options = []
			let legalEntities = this.$store.state.activeSG.supplierLegalEntities
			let index = 0
			for (const le in legalEntities) {
				index = index + 1
				let tempItem = {
					id: index,
					legalEntityId: le,
					name: legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				}
				options.push(tempItem)
			}
			return options
		},
		templateNames() {
			let names = []

			if (this.$store.state.extractionTemplates.length) {
				this.$store.state.extractionTemplates.forEach((template, index) => {
					if (template?.name !== undefined) {
						let tempItem = {
							id: index,
							name: template.name
						}
						names.push(tempItem)
						index++
					}
				})
			}
			return names
		},
	},
	methods: {
		toggleFields(field) {
			this[field] = !this[field]
		},
		giveDraggableColor(field) {
			this.selectedField = field
		},
		updateTemplateName(data) {
			if (typeof data === 'object') data = data[0]

			const selectedTemplate = this.templateNames[data]

			this.$store.commit("setSelectedExtractionTemplateName", selectedTemplate.name)
			this.formData = this.$store.state.extractionTemplates.find(el => el.name === selectedTemplate.name)

			if (this.formData === undefined) this.formData = {}
			if (!this.formData.location) this.formData.location = ''
			if (this.formData.useSystemDate === undefined) this.formData.useSystemDate = false
			if (this.formData.defaultCountry === undefined) this.formData.defaultCountry = "No default country"
		},
		selectedLe(id) {
			return this.$store.state.activeSG.supplierLegalEntities[id].internalName ? this.$store.state.activeSG.supplierLegalEntities[id].internalName : this.$store.state.activeSG.supplierLegalEntities[id].name
		},
		download() {
			window.open(this.formData.pdfFile)
		},
		addExtractionTemplate() {
			this.$store.state.displayAddExtractionTemplate = true
		},
		getExtractionTemplateIds() {
			this.$store.state.extractionTemplateIds = []
			if (this.$store.state.activeSG.channelsConfig.docImage.extractionConfig) {
				this.$store.commit('updateExtractionTemplateIds', this.$store.state.activeSG.channelsConfig.docImage.extractionConfig)
				this.$store.commit('updateExtractionTemplateIds', this.$store.state.activeSG.channelsConfig.docImage.extractionConfig)
			}
		},
		getExtractionTemplate() {
			this.formData = []
			this.$store.commit('clearExtractionTemplate')

			if (this.$store.state.extractionTemplateIds.length) {
				console.log(this.$store.state.extractionTemplateIds)
				this.$store.state.extractionTemplateIds.forEach(async template => {
					await this.$store.dispatch('getExtractionTemplate', {
						templateId: template,
						token: this.$root.token
					})
				})
			}

		}
	}
}
</script>
<style scoped>
.makeSquare {
	border: 1px solid darkgray;
}

.green {
	border: 1px solid rgb(145, 199, 20);
}

.no-pdf {
	text-align: center;
	margin-top: 40px;
}

.ingestion-content {
	min-height: 600px;
}

.move-address-location {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 170px auto;
}

.button-container.add-new-template {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.z-index-100 {
	z-index: 100;
}

.default-date {
	padding-right: 20px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 90% 10%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}

.one-line > div {
	width: 100%;
}

.one-line-grid {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 60% 40%;
}

.one-line-grid p:nth-child(2) {
	justify-self: end;
}

.button-container-template {
	display: grid;
	grid-template-columns: 65% 35%;
	grid-template-rows: 100%;
}

.button-container-template > div {
	justify-self: end;
}

.button-container-template > p {
	justify-self: start;
}

.table-field-container {
	border-top: 1px solid grey;
	padding-top: 6px;
}

.one-line-grid > div {
	justify-self: end;
}

.button-container {
	display: grid;
	grid-template-columns: 65% 35%;
	grid-template-rows: 100%;
}

.button-container > p {
	justify-self: start;
}

.button-container > div {
	justify-self: end;
}
</style>