import axios from "axios"

export default {
	postReport(context, payload) {
		axios.request({
			url: "api/admin/supplier-groups/reports/" + payload.reportType,
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: {
				supplierGroupLegalEntities: payload.SG,
				from: payload.from,
				to: payload.to
			}
		})
			.then((response) => {
				context.rootState.responseStatus = 200
				context.commit('setCsvContent', response.data, {root: true})
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.log("globalJobs-error: ", exception, {root: true})
			})
	}
}