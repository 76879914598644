<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditIngestion}"
		class="box shadow-none col-span-6"
	>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row">
				<!--sFTP-->
				<div class="flex flex-col w-1/2 mr-12">
					<p class="text-lg font-bold mt-3 mb-2">SFTP</p>
					<Field
						id="ftpUsername"
						title="FTP Username:"
						type="text"
						:modelValue="supplierGroup.sender.sFTP.ftp_username"
						:disabled="true"
					/>
					<label class="font-bold" for="editFtpPassword">FTP password:</label>

					<button
						class="btn bg-theme-1 text-white btn-sm w-24 mr-1 mb-2"
						@click="view"
						style="width: 150px"
					>
						Change FTP Password
					</button>

					<Field
						id="ingestionInterval"
						title="Ingestion interval:"
						type="text"
						:modelValue="ingestion"
						:disabled="true"
					/>
					<Field
						id="locateFileTimeout"
						title="Number of tries to process attachments:"
						type="text"
						:modelValue="supplierGroup.storageConfig.locateFileTimeout"
						:disabled="true"
					/>
					<!--XSD Files-->
					<div class="">
						<p class="text-lg font-bold mb-2">XSD Files*</p>
						<div
							:key="index"
							v-for="(item, index) in supplierGroup.xmlConfig.xsdFiles"
							class="flex flex-row flex-nowrap"
						>
							<div class="mb-3 mr-3 w-1/3">
								<label class="font-bold form-label">XSD Type:</label>
								<input
									disabled
									id="xsdType"
									type="text"
									class="form-control w-full"
									:value="item.xsdType"
								/>
							</div>
							<div class="mb-3 w-2/3">
								<label class="font-bold form-label">XSD Location:</label>
								<div class="input-group">
									<input
										disabled
										id="xsdLocation"
										type="text"
										class="form-control w-full"
										:value="item.xsdLocation"
									/>
									<div
										class="input-group-text"
										@click="download(item.xsdLocation)"
										style="cursor:pointer"
									>
										<span class="material-icons" style="font-size: 20px">file_download</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--API Configuration-->
				<div class="flex flex-col w-1/2">
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2"> Dynamic attachments:</label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="dynamicAttachments"
							:bool="dynamicAttachments"
							:disabled="true"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Negative creditnote: </label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="creditNoteNegative"
							:bool="creditNoteNegative"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Add zero tax segments: </label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="addZeroTaxSegments"
							:bool="addZeroTaxSegments"
							:disabled="true"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2"> Hold Release:</label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="holdRelease"
							:bool="holdRelease"
							:disabled="true"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">
							API configuration:
						</label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="apiConfigComputed"
							:bool="apiConfigComputed"
							:disabled="true"
						/>
					</div>
					<div class="mb-3 flex" v-if="apiKeyComputed !== ''">
						<button
							class="btn bg-theme-1 text-white btn-sm w-24 mr-1 mb-2"
							id="clearCache"
							@click="clearCache"
						>
							Clear Cache
						</button>
					</div>
					<div v-if="apiConfigComputed">
						<Field
							class="ml-6"
							@copy="copy('apiKey')"
							:isCopied="copyApiBool"
							:copySuffix="true"
							id="apiKey"
							title="API Key:"
							type="text"
							:modelValue="apiKeyComputed"
							:disabled="true"
						/>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Create LE:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiCreateLeComputed"
								:bool="apiCreateLeComputed"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Validate UBL:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiValidateUbl"
								:bool="apiValidateUbl"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Public link:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiPublicLinksComputed"
								:bool="apiPublicLinksComputed"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Ingestion:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiIngestionComputed"
								:bool="apiIngestionComputed"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Manage payments:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiManagePaymentsComputed"
								:bool="apiManagePaymentsComputed"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Manage Users:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiManageUsersComputed"
								:bool="apiManageUsersComputed"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<p class="font-bold text-lg form-label mb-0 mr-2">OpenID Connect apisix:</p>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="apiOidcEnabled"
								:bool="apiOidcEnabled"
								:disabled="true"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<p class="font-bold text-lg form-label mb-0 mr-2">Webhook config:</p>
							<ToggleSlide
								class="w-min pointer-events-none"
								:key="webhookConfigComputed"
								:bool="webhookConfigComputed"
								:disabled="true"
							/>
						</div>
						<div v-if="webhookConfigComputed">
							<Field
								class="ml-12"
								id="webhookUrl"
								title="URL:"
								type="text"
								:modelValue="webhookUrlComputed"
								:disabled="true"
								@copy="copy('webhookUrl')"
								:isCopied="copyWebhookUrl"
								:copySuffix="true"
							/>
							<div class="mb-3 ml-12 flex">
								<label class="font-bold form-label mb-0 mr-2">Errors:</label>
								<ToggleSlide
									class="w-min pointer-events-none"
									:key="webhookErrorsComputed"
									:bool="webhookErrorsComputed"
									:disabled="true"
								/>
							</div>
							<div class="mb-3 ml-12 flex">
								<label class="font-bold form-label mb-0 mr-2">Notifications:</label>
								<ToggleSlide
									class="w-min pointer-events-none"
									:key="webhookNotificationsComputed"
									:bool="webhookNotificationsComputed"
									:disabled="true"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
import Field from "../Field.vue"
import ToggleSlide from "../ToggleSlide.vue"

export default {
	name: "ViewIngestion",
	props: {
		ingestion: String,
	},
	components: {Field, ToggleSlide},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				this.supplierGroup = data;
			},
			deep: true,
		},
	},
	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			copyApiBool: false,
			copyWebhookUrl: false,
		}
	},
	methods: {
		view() {
			this.$store.state.displayEditPass = true
		},
		download(pdfPath) {
			window.open(this.supplierGroup.channelsConfig.docImage.configPortal.portalLink + "/api/branding/proxy/" + pdfPath)
		},
		clearCache() {
			this.$store.dispatch('globalActions/clearCache').catch((err) => console.error(err))
		},
		copy(isFor) {
			switch (isFor) {
				case "apiKey":
					navigator.clipboard.writeText(this.apiKeyComputed)
					this.copyApiBool = true
					setTimeout(() => {
						this.copyApiBool = false
					}, 750)
					break
				case "webhookUrl":
					navigator.clipboard.writeText(this.webhookUrlComputed)
					this.copyWebhookUrl = true
					setTimeout(() => {
						this.copyWebhookUrl = false
					}, 750)
					break
			}
		}
	},
	computed: {
		holdRelease() {
			return this.supplierGroup.storageConfig.holdRelease ? this.supplierGroup.storageConfig.holdRelease : false
		},
		addZeroTaxSegments() {
			return this.$store.state.activeSG.storageConfig.addZeroTaxSegments ? this.$store.state.activeSG.storageConfig.addZeroTaxSegments : false
		},
		creditNoteNegative() {
			return this.supplierGroup.storageConfig.creditNoteNegative ? this.supplierGroup.storageConfig.creditNoteNegative : false
		},
		webhookErrorsComputed() {
			return !!this.supplierGroup.apiConfig?.webhookConfig?.errors
		},
		apiPublicLinksComputed() {
			return !!this.supplierGroup.apiConfig?.publicLinks
		},
		apiCreateLeComputed() {
			return !!this.supplierGroup.apiConfig?.manageLegalEntities
		},
		apiValidateUbl() {
			return !!this.supplierGroup.apiConfig?.validateUbl
		},
		apiIngestionComputed() {
			return !!this.supplierGroup.apiConfig?.ingestion
		},
		apiManagePaymentsComputed() {
			return !!this.supplierGroup.apiConfig?.managePayments
		},
		apiManageUsersComputed() {
			return !!this.supplierGroup.apiConfig?.manageUsers
		},
		apiOidcEnabled(){
			return this.supplierGroup.apiConfig?.oidcEnabled
		},
		webhookConfigComputed() {
			return !!this.supplierGroup.apiConfig?.webhookConfig
		},
		webhookUrlComputed() {
			if (this.supplierGroup.apiConfig?.webhookConfig?.url) {
				return this.supplierGroup.apiConfig.webhookConfig.url
			}
			return ""
		},
		apiConfigComputed() {
			if (this.supplierGroup.apiConfig) {
				return this.$store.state.activeSG.apiConfig.apiKey !== ''
			}
			return false
		},
		dynamicAttachments() {
			if (this.$store.state.activeSG.storageConfig.dynamicAttachments) {
				return this.$store.state.activeSG.storageConfig.dynamicAttachments
			}
			return false
		},
		apiKeyComputed() {
			if (this.supplierGroup.apiConfig) {
				return this.supplierGroup.apiConfig.apiKey
			}
			return ""
		},
		webhookNotificationsComputed() {
			if (this.supplierGroup.apiConfig?.webhookConfig) {
				return this.supplierGroup.apiConfig.webhookConfig.notifications
			}
			return false
		}
	}
}
</script>