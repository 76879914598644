<template>
	<div>
		<div class="flex flex-row" style="margin-bottom: 10px">
			<p class="mr-2 font-bold">Reporting: </p>
			<ToggleSlide
				class="w-min"
				@update-value="reporting = !reporting"
				:bool="reporting"
			/>
		</div>
		<div v-if="reporting" class="ml-4">
			<button class="btn size-10" @click="addRecipient">
				+
			</button>
			<div v-for="(item, index) in reportingEmails" :key="index">
				<div class="flex flex-row w-2/3 items-center">
					<email-language-selector class="basis-full" v-model="reportingEmails[index]"/>
					<button class="btn size-10 mt-6" @click="removeRecipient(index)" v-if="showRemoveRecipient">
						-
					</button>
				</div>
				<p v-if="item.isValid === false && item.email === ''"  class="error">Please provide an email address</p>
				<p v-else-if="item.isValid === false"  class="error">Email address is incorrect, please provide a correct email address</p>
			</div>
			<button
				id="setReporting"
				class="btn mt-2 mb-4 text-white"
				:style="{backgroundColor: setColorSetButton}"
				@click="updateReporting"
			>
				Set Reporting
			</button>
		</div>
	</div>
</template>

<script>
import ToggleSlide from "@/components/ToggleSlide.vue"
import EmailLanguageSelector from "@/components/AddLegalEntity/EmailLanguageSelector.vue";
export default {
	components: {EmailLanguageSelector, ToggleSlide},
	props: ['colors', 'createdLe'],
	data() {
		return {
			reporting: false,
			colorSetButton: null,
			reportingEmails: [
				{email: '', language: 'NL', isValid: null}
			]
		}
	},
	computed: {
		showRemoveRecipient() {
			return this.reportingEmails.length !== 1
		},
		setColorSetButton(){
			if(this.colorSetButton === null) return this.colors.blue
			else if (this.colorSetButton === 'success') return this.colors.success
			else return this.colors.error
		},
	},
	methods: {
		addRecipient() {
			this.reportingEmails.push({email: "", language: "NL", isValid: null})
		},
		removeRecipient(index) {
			this.reportingEmails.splice(index, 1)
		},
		async updateReporting() {
			let errors= false
			this.reportingEmails.forEach(info => {
				if (!info.isValid) errors = true
			})
			if (errors) {
				this.colorSetButton = 'error'
				return
			}

			let reportData = {
				reportingConfig: {
					adminEmails: null,
					receiverEmails: {},
					generateXlsx: {}
				}
			}
			this.$store.state.reportingTypes.forEach(type => {
				reportData.reportingConfig.generateXlsx[type] = false
			})

			let reportingEmails = {}

			this.reportingEmails.forEach(info => {
				reportingEmails[info.email] = info.language
			})
			reportData.reportingConfig.receiverEmails['MAIL_ERRORS'] = reportingEmails

			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				leId: this.createdLe,
				edit: "report-settings",
				body: reportData
			}
			await this.$store.dispatch('EditSupplierGroup', payload)
			if (this.$store.state.responseStatus === 200) this.colorSetButton = "success"
			else this.colorSetButton = "error"
		}
	}
}
</script>