<template>
	<div class="form-container">
		<div class="form-item ">
			<p class="mb-1 font-bold">Identifier:</p>
			<input
				type='text'
				v-model="modelValue.identifier"
				@input="changeFormData"
				class="form-control text-popup"
			/>
		</div>
		<div class="form-item ml-2.5">
			<p class="mb-1 font-bold">Country code:</p>
			<Dropdown
				id="externalPortalLanguage"
				@update-value="updateSelectedLanguage"
				:data="countryCodes"
				:selected="modelValue.countryCode"
			/>
		</div>
	</div>

</template>

<script>
import VueSelect from "vue3-select"
import Dropdown from "@/components/Dropdown.vue";
import countryCodes from "@/countryCodes";

export default {
	components: {Dropdown, VueSelect},
	model: {
		prop: 'modelValue',
		event: 'change'
	},
	props: ['modelValue'],
	data() {
		return {
			formData: {
				identifier: '',
				countryCode: countryCodes.find(l => l.name === 'BE').name,
			}
		}
	},
	computed: {
		countryCodes() {
			return countryCodes
		},
	},
	methods: {
		updateSelectedLanguage(value) {
			if (typeof value !== "object") {
				this.modelValue.countryCode = this.countryCodes[value].name
				this.$emit('update:modelValue', this.modelValue)
			}
		},
		changeFormData(e) {
			this.modelValue[e.target.id] = e.target.value
			this.$emit('update:modelValue', this.modelValue)
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 0px);
	grid-template-rows: 50% 50%;
	grid-auto-flow: row;
	padding-bottom: 0;
	grid-column-gap: 0;
}

@media only screen and (max-width: 600px) {
	.form-container {
		grid-template-columns: 100%;
		grid-template-rows: 30% 30%;
	}

	.form-item:first-child {
		padding-bottom: 0;
	}
}
</style>