import axios from "axios"

export default {
	startGlobalJobs(context, payload) {
		axios.request({
			url: "api/scheduler/jobs/global/start/" + payload,
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.dispatch("fetchSchedule", null)
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("globalJobs-error: ", exception)
			})
	},
	stopGlobalJobs(context, payload) {
		axios.request({
			url: "api/scheduler/jobs/global/stop/" + payload,
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.dispatch("fetchSchedule", null)
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.log("globalJobs-error: ", exception)
			})
	},
	toggleSgJob(context, payload) {
		let url = context.rootState.baseUrl + 'api/scheduler/'
		if (payload.status) url = url + 'stop/' + payload.sgId + '/type/' + payload.reportType
		else url = url + 'start/' + payload.sgId + '/type/' + payload.reportType

		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		)
			.then(() => {
				context.dispatch("fetchSchedule", null)
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	},

	printSpeosSchedule(context) {
		axios.request({
			url: "api/printing/process",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("printSpeosSchedule-error: ", exception)
			})
	},
	printSpeosFeedback(context) {
		axios.request({
			url: "api/admin/speos/feedback",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("printSpeosFeedback-error: ", exception)
			})
	},
	easypostFeedback(context) {
		axios.post(context.rootState.baseUrl + "api/printing/easypost-process-feedback", {}, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		}).catch((exception) => {
			console.error("easypostFeedback-error: ", exception)
		})
	},
	printSpeosFeedbackReprocess(context, payload) {
		axios.request({
			url: "api/admin/speos/feedback/reprocess",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: payload.body
		})
			.then(() => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("printSpeosFeedbackReprocess-error: ", exception)
			})
	},

	startQuickActionIngestion(context, payload) {
		axios.request({
			url: "api/ingestion/process/" + payload,
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("startQAIngestion-error: ", exception)
			})
	},
	async startPrint(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.startPrintEasyPost.replace(":supplierGroupId", context.rootState.activeSG.supplierGroupId)
		let resp = await axios.post(url, {}, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
		})
		return resp.status
	},
	startQuickActionPrinting(context, payload) {
		axios.request({
			url: "api/printing/" + payload + "/prepare",
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("startQAIngestion-error: ", exception)
			})
	},
	startIngestionSchedule(context, payload) {
		axios.request({
			url: "api/scheduler/start/" + payload + "/type/INGESTION",
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.dispatch('fetchSchedule', null)

				context.commit("setIngestionStatus", {type: "start", sg: payload}, {root: true})
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("fetchSchedule-error: ", exception)
			})
	},
	stopIngestionSchedule(context, payload) {
		axios.request({
			url: "api/scheduler/stop/" + payload + "/type/INGESTION",
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.dispatch('fetchSchedule', null)
				context.commit("setIngestionStatus", {type: "stop", sg: payload}, {root: true})
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.log("fetchSchedule-error: ", exception)
			})
	},
	stopPrintSchedule(context, payload) {
		axios.request({
			url: context.rootState.baseUrl + "api/scheduler/stop/" + payload + "/type/PRINT",
			method: "get",
			baseURL: context.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.responseStatus = 200
				context.dispatch('fetchSchedule', null)
				context.commit('overviewTableDataUpdated', true, {root: true})
			})
			.catch((exception) => {
				context.responseStatus = 400
				console.error("fetchSchedule-error: ", exception)
			})
	},

	triggerReport(context, payload) {
		axios.request({
			url: "api/admin/supplier-groups/" + payload.sgId + "/reports/" + payload.type + "/trigger",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then((response) => {
				//NOTHING || All actions take place in backend
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("triggerReport-error: ", exception)
			})
	},

	twikeyMandateSchedule(context, payload) {
		axios.request({
			url: "api/admin/mandates-feed",
			method: payload,
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				//NOTHING || All actions take place in backend
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("twikeyMandate-error: ", exception)
			})
	},
	twikeyTransactionSchedule(context, payload) {
		axios.request({
			url: "api/admin/transactions-feed",
			method: payload,
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				//NOTHING || All actions take place in backend
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("twikeyTransaction-error: ", exception)
			})
	},
	twikeyInvoiceFeedPolling(context, payload) {
		axios.request({
			url: "api/admin/invoices-feed",
			method: payload,
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				//NOTHING || All actions take place in backend
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("twikeyMandate-error: ", exception)
			})
	},

	fetchSchedule(context) {
		axios.request({
			url: context.rootState.schedulerUrl,
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then((response) => {
				const res = response.data.data
				context.rootState.responseStatus = 200
				context.commit("overviewTableDataUpdated", true, {root: true})
				context.commit('setSchedule', res, {root: true})
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("fetchSchedule-error: ", exception)
			})
	},
	pauseApiIngestion(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.pauseApiIngestion.replace(':supplierGroupId', payload.supplierGroupId)
		axios.post(url, {pauseIngestion: payload.pauseIngestion}, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			}
		}).then(response => {
			const indexSupplierGroup = context.rootState.supplierGroups.findIndex(s => s.supplierGroupId === payload.supplierGroupId)
			context.rootState.supplierGroups[indexSupplierGroup].apiConfig.pauseIngestion = response.data.data
			context.rootState.pauseApiIngestionChanged = {
				value: response.data.data,
				sg: payload.supplierGroupId
			}
		})
			.catch(err => {
				context.rootState.responseStatus = 400
				console.error("pauseApiIngestion: " + err)
			})
	},
	async printSchedule(context, payload) {
		let url = context.rootState.baseUrl + 'api/scheduler/:action/:supplierGroupId/type/PRINT'.replace(':supplierGroupId', payload.supplierGroupId)
		if (payload.printSchedule) url = url.replace(':action', 'stop')
		else url = url.replace(':action', 'start')

		return await axios.get(url, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			}
		}).catch(err => {
			context.rootState.responseStatus = 400
			context.commit('displayErrorAlert', true, {root: true})
			console.error("Print schedule: " + err)
		})
	},
	async pauseAllApiIngestion(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.pauseAllApiIngestion
		await axios.post(url, payload, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			}
		}).then(resp => console.log(resp))
			.catch(err => {
				context.rootState.responseStatus = 400
				console.error("pauseAllApiIngestion: " + err)
				context.commit('displayErrorAlert', true, {root: true})
			})
	},
	async updatePrintschedule(context, payload) {
		axios.request({
			url: "api/scheduler/" + payload.type + "/" + payload.sg + "/type/PRINT",
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.dispatch('fetchSchedule', null)
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("fetchSchedule-error: ", exception)
			})
	}
}