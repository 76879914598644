<template>
	<div :class="{'brightness-95': this.$store.state.displayEditLeBankAccounts}" class="box col-span-6 min-height-800"
	>
		<modify-peppol-item
			:peppolIdentifier="selectedIdentifier"
			:modifyItem="modifyItem"
			:header='header'
		/>

		<EditPeppol :dropdownList="peppolIdetifiers"></EditPeppol>
		<ConfirmDialog @close="checkDelete" :show="showConfirm"/>

		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 min-height-600">
			<div>
				<div>
					<div class="flex flex-row ">
						<div class="w-1/3">
							<button class="btn shadow-md mr-2 nymus-blue color-white position-add"
									@click="clearCache">
								Clear cache
							</button>
						</div>
						<div class="centered-label-wrapper">
							<label class="centered-label">!!!!!!If peppol identifiers are changed, do not forget to
								stop/start ingestion!!!!!</label>
						</div>
					</div>
					<div class="flex flex-row flex-wrap">
						<div class="w-1/2 pr-64">
							<Field
								title="Peppol Identifier (ID)"
								type="text"
								v-model.trim="inputFieldsPeppolParticipants.peppolId"
								@click="clearNotification"
								:has-error="peppolIdHasError"
							/>
							<span v-if="peppolIdHasError" class="text-primary-3">Peppol id is required.</span>
						</div>
						<div class="w-1/2 pl-8 pr-64">
							<p class="font-bold form-label">Country code:</p>
							<Dropdown
								@update-value="updateInputCountryCode"
								class=""
								:data="countryCodes"
								:selected=inputFieldsPeppolParticipants.countryCode
							/>
						</div>

						<div class="w-1/2 pr-64">
							<Field
								title="Peppol Name"
								type="text"
								v-model.trim="inputFieldsPeppolParticipants.peppolName"
								@click="clearNotification"
								:has-error="nameHasError"
							/>
							<span v-if="nameHasError" class="text-primary-3" style="width: 25%">
								Peppol name is required.
							</span>
						</div>

						<div class="w-1/2 pl-8 flex flex-row" >
							<div class=" flex flex-row" style="margin-bottom: 10px; width: 22.3%">
								<p class="font-bold form-label w-full" style="margin-top: 23px ">Is receiver: </p>
								<toggle-slide
									:bool="inputFieldsPeppolParticipants.isReceiver"
									:renew-bool="true"
									@update-value="updateBool($event,'isReceiver')"
								></toggle-slide>
							</div>
							<div class=" flex flex-row pl-28" style="margin-bottom: 10px; width: 45%">
								<p class="font-bold form-label w-full" style="margin-top: 23px; flex-basis: 40%">Is sender:</p>
								<toggle-slide
									style="flex-basis: 30%"
									:bool="inputFieldsPeppolParticipants.isSender"
									:renew-bool="true"
									@update-value="updateBool($event,'isSender')"
								></toggle-slide>
							</div>
						</div>
						<div class="add-button">
							<button class="btn shadow-md mr-2 nymus-blue color-white position-add marginAddBtn"
									@click="add">
								Add new
							</button>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="mb-4">
					<p class="font-bold">List of existed Peppol identifiers: </p>
					<div v-for="(item, key) in Object.keys(existedPeppolIdentifiers)" :key="key">
						<div class="ml-6">
							{{ item }} - {{ existedPeppolIdentifiers[item].name }} -
							{{ existedPeppolIdentifiers[item].countryCode }} <span
							v-if="existedPeppolIdentifiers[item].isReceiver">- receiver</span>
							<span v-if="existedPeppolIdentifiers[item].isSender">- sender</span>
						</div>

					</div>
				</div>

				<div class="retrieve-info">
					<div class="input-outer">
						<p class="font-bold">Peppol identifier: </p>
						<Dropdown
							@update-value="updateSelectedPeppolIdentifier"
							:data="peppolIdetifiers"
							:selected=selectedPeppolIdentifier
						/>
					</div>

					<div class="button-container-top">
						<button class="btn shadow-md mr-2 nymus-blue color-white button-1" @click="retrieveInfo">
							Retrieve info
						</button>

						<button class="btn shadow-md mr-2 nymus-orange color-white"
								@click="openConfWindow"
								v-if="this.$store.state.peppolIdentifiers.participantNotFound">
							Delete Peppol identifier
						</button>

						<div v-if="this.$store.state.peppolIdentifiers.participantFound">
							<button
								v-if="displayMitrateToPeppolButton"
								class="btn shadow-md mr-2 nymus-blue color-white button-1"
								@click="migrateHermesToPeppol"
							>
								Migrate Hermes to peppol
							</button>
							<button
								v-if="displayMigrateToNymus"
								class="btn shadow-md mr-2 nymus-blue color-white button-1"
								@click="displayMigrateToNymusDialog = true"
							>
								Migrate to peppol
							</button>
							<button class="btn shadow-md mr-2 nymus-blue color-white button-1"
									@click="callModifyPeppolDocument('Create Peppol document', 'createDocument')"
							>
								Add new document
							</button>
							<button v-if='this.$store.state.peppolIdentifiers.peppolRetrievedData.information.length'
									class="btn shadow-md mr-2 nymus-orange color-white button-2"
									@click="callModifyPeppolDocument('Delete Peppol document', 'deleteDocument')"
							>
								Delete document
							</button>
							<button class="btn shadow-md mr-2 nymus-orange color-white button-2 delete-participant"
									@click="callModifyPeppolDocument('Delete Peppol participant', 'deleteParticipant')"
							>
								Delete participant
							</button>
						</div>
					</div>
				</div>
				<span v-if="errors.retrieveInfo && peppolIdetifiers.length > 1" class="text-primary-3">Please, select Peppol identifier</span>
				<span v-if="errors.retrieveInfo && peppolIdetifiers.length === 1" class="text-primary-3">Please, create a peppol identifier before you retrieve info</span>
				<div class="info-container" v-if="this.$store.state.peppolIdentifiers.peppolInfoDisplay">
					<div v-if="this.$store.state.peppolIdentifiers.participantFound">
						<div class="info-row table-header">
							<div class="row-item">
								<div class="center">
									Detail URL
								</div>
							</div>
							<div class="row-item">
								<div class="center">
									Access point url
								</div>
							</div>
							<div class="row-item">
								<div class="center">
									Document type
								</div>
							</div>
						</div>

						<div v-if='this.$store.state.peppolIdentifiers.peppolRetrievedData.information.length'>
							<div
								v-for="(info, key) in this.$store.state.peppolIdentifiers.peppolRetrievedData.information"
								:key=key>
								<div class="info-row">
									<div class="row-item detail-url">
										{{ info.detailUrl }}
									</div>
									<div class="row-item ">
										<div class="center">{{ info.accessPointUrl }}</div>
									</div>
									<div class="row-item">
										<div class="center">{{ info.documentType }}</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div v-if="this.$store.state.peppolIdentifiers.participantNotFound">
						<div class="mb-2">
							There is no information to display.
						</div>
						<div class="create-participant">
							<div class="input-outer">
								<p class="font-bold">SMP provider:</p>
								<Dropdown
									@update-value="smpProvider"
									:data="smpProviders"
									:selected=selectedSmpProvider
								/>
							</div>
							<div class="button-container">
								<button class="btn shadow-md mr-2 nymus-blue color-white button-1"
										@click="createParticipant">
									Create participant
								</button>
							</div>
						</div>

						<div v-if="this.$store.state.peppolIdentifiers.displayCreateDocument">
							<div class="create-document">
								<div class="input-outer">
									<p class="font-bold">Document type:</p>
									<Dropdown
										@update-value="updateDocTypeIdentifier"
										:data="documentTypeIdentifiers"
										:selected=selectedDocumentTypeIdentifier
									/>
								</div>
								<div class="button-container">
									<button class="btn shadow-md mr-2 nymus-blue color-white button-1"
											@click="createDocument">
										Create document
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--To make sure we can see most of the dropdown box-->
				<div :style="{height: showExtraHeight}"></div>
			</div>
		</div>
	</div>
	<pop-up-window
		:show="this.displayMigrateToPeppolDialog || this.displayMigrateToNymusDialog"
		:showClose="false"
		fixed
		variable-top="500px"
		width40
	>
		<template #header>{{displayMigrateToPeppolDialog ? 'Migrate Hermes to Peppol' : 'Migrate to Nymus' }} </template>
		<template #content>
			<div v-if="displayMigrateToPeppolDialog" style="margin-left: 10%;">
				<p style="color: red">!!IMPORTANT!! Only click on "Migrate to peppol if the key below is an key and not
					an error</p>
				<p>Key or error: {{ migrationKey }}</p>
			</div>
			<div v-if="displayMigrateToNymusDialog" style="margin-left: 10%;">
				<Field
					title="Enter the migration key retrieved from the access point:"
					type="text"
					v-model.trim="migrationKey"
					@click="errors.inputMigrationKey = false"
					:has-error="errors.inputMigrationKey"
				/>
				<span v-if="errors.inputMigrationKey" class="text-primary-3">Please,enter a migration key</span>
			</div>
			<div style="text-align: center; margin-top: 10px;">
				<button class="btn shadow-md mr-2 nymus-blue color-white button-1" @click="migrateHermes">
					Migrate to Nymus AP
				</button>
				<button
					class="btn shadow-md mr-2 nymus-orange color-white button-2"
					@click="displayMigrateToPeppolDialog = false; displayMigrateToNymusDialog = false"
				>
					cancel
				</button>
			</div>

		</template>
	</pop-up-window>
</template>

<script>
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
import ModifyPeppolItem from "./ModifyPeppolItem";
import ConfirmDialog from "../ConfirmDialog.vue"
import EditPeppol from "@/components/EditLegalEntity/EditPeppol.vue"
import ToggleSlide from "@/components/ToggleSlide.vue";
import countryCodes from "@/countryCodes";
import PopUpWindow from "@/components/PopUp.vue";

export default {
	name: "ViewPeppolIdentifiers",
	components: {
		PopUpWindow,
		ToggleSlide,
		Field,
		Dropdown,
		ModifyPeppolItem,
		ConfirmDialog,
		EditPeppol
	},
	data() {
		return {
			inputFieldsPeppolParticipants: {
				peppolId: '',
				peppolName: '',
				isSender: false,
				isReceiver: false,
				countryCode: 'BE'
			},

			peppolIdentifier: '',
			errors: {
				peppolIdentifier: false,
				retrieveInfo: true,
				inputMigrationKey: false
			},
			selectedPeppolIdentifier: this.$store.state.activeLE.peppolParticipants || this.$store.state.activeLE.peppolIdentifiers ? "Select Peppol identifier" : "No identifiers",
			selectedSmpProvider: this.$store.state.smpProviders[0].name,
			selectedDocumentTypeIdentifier: this.$store.state.documentTypeIdentifiers[0].name,

			displayMigrateToPeppolDialog: false,
			displayMigrateToNymusDialog: false,
			migrationKey: '',

			smpProviders: this.$store.state.smpProviders,
			documentTypeIdentifiers: this.$store.state.documentTypeIdentifiers,

			modifyItem: '',
			header: '',
			showConfirm: false,
			displayNotification: false,
			nameHasError: false,
			peppolIdHasError: false,
		}
	},
	watch: {
		activeLE(newVal) {
			if (newVal.peppolIdentifiers) {
				this.existedPeppolIdentifiers = newVal.peppolIdentifiers
			} else {
				this.existedPeppolIdentifiers = []
			}
		},
		"peppolItemChanged"(newVal) {
			if (newVal) {
				if (this.$store.state.peppolIdentifiers.peppolDocumentCreated || this.$store.state.peppolIdentifiers.peppolDocumentDeleted) {
					this.retrieveInfo();
					this.$store.commit("peppolDocumentCreated", false);
					this.$store.commit("peppolDocumentDeleted", false);

				} else {
					this.selectedPeppolIdentifier = "Select Peppol identifier";

				}
				this.$store.commit('peppolItemChanged', false)
			}
		},
		displayEditPeppol(newVal) {
			if (newVal) {
				this.clearNotification();
			}
		}
	},
	computed: {
		countryCodes() {
			return countryCodes
		},
		displayEditPeppol() {
			return this.$store.state.displayEditPeppol
		},
		displayMitrateToPeppolButton() {
			let isHermes = false
			console.log(this.$store.state.peppolIdentifiers.peppolRetrievedData.information)
			this.$store.state.peppolIdentifiers.peppolRetrievedData.information.forEach(info => {
				if (info.accessPointUrl.includes('hermes-belgium')) {
					isHermes = true
				}
			})
			return isHermes
		},
		displayMigrateToNymus(){
			let registeredOnNymus = true
			console.log(this.$store.state.peppolIdentifiers.peppolRetrievedData.information)
			this.$store.state.peppolIdentifiers.peppolRetrievedData.information.forEach(info => {
				if (info.accessPointUrl.includes('hermes-belgium') || info.accessPointUrl.includes('nymus')) {
					registeredOnNymus = false
				}
			})
			return registeredOnNymus
		},
		showExtraHeight() {
			if (this.$store.state.peppolIdentifiers.participantNotFound) return '150px'
			if (!this.$store.state.peppolIdentifiers.peppolInfoDisplay || !this.$store.state.peppolIdentifiers.participantFound) return '250px'
			else return '110px'
		},
		selectedIdentifier() {
			let identifier = this.selectedPeppolIdentifier;
			if (identifier.includes('-')) {
				identifier = identifier.split('-')[0]
			}
			return identifier
		},
		existedPeppolIdentifiers() {
			let identifiers = [];
			if (this.$store.state.activeLE.peppolIdentifiers) {
				identifiers = [...this.$store.state.activeLE.peppolIdentifiers]
			}
			if (this.$store.state.activeLE.peppolParticipants) {
				for (let identifier in this.$store.state.activeLE.peppolParticipants) {
					identifiers[identifier] = {
						name: this.$store.state.activeLE.peppolParticipants[identifier].name,
						isSender: this.$store.state.activeLE.peppolParticipants[identifier].isSender,
						isReceiver: this.$store.state.activeLE.peppolParticipants[identifier].isReceiver,
						countryCode: this.$store.state.activeLE.peppolParticipants[identifier].countryCode
					}
				}
			}
			return identifiers
		},
		peppolItemChanged() {
			return this.$store.state.peppolIdentifiers.peppolItemChanged
		},
		peppolIdetifiers() {
			if (this.$store.state.activeLE.peppolParticipants || this.$store.state.activeLE.peppolIdentifiers) {
				let identifiers = [{id: 0, name: "Select Peppol identifier"}]
				let index = 1;
				for (let id in this.existedPeppolIdentifiers) {

					let tempItem = {id: index, name: id + '-' + this.existedPeppolIdentifiers[id].name}
					identifiers.push(tempItem)
					index = index + 1;
				}
				return identifiers
			}

			return [{id: 0, name: "No identifiers"}]
		},

		activeLE() {
			return this.$store.state.activeLE
		}
	},
	methods: {
		createParticipant() {
			let identifier = this.selectedPeppolIdentifier;
			if (identifier.includes('-')) identifier = identifier.split('-')[0]
			const data = this.existedPeppolIdentifiers[identifier]

			let payload = {
				identifier: identifier,
				smpProvider: this.selectedSmpProvider,
				name: data.name,
				countryCode: data.countryCode
			}
			try {
				this.$store.dispatch("peppol/createPeppolParticipant", payload)
			} catch (error){
				this.$store.commit('displayErrorAlertText', "Error: " + error.message)
				this.$store.commit('displayErrorAlert', true)
			}
		},
		createDocument() {
			let identifier = this.selectedPeppolIdentifier;

			if (identifier.includes('-')) {
				identifier = identifier.split('-')[0]
			}

			let formData = {
				identifier: identifier,
				smpProvider: this.selectedSmpProvider,
				documentTypeIdentifier: this.selectedDocumentTypeIdentifier
			}

			try {
				this.$store.dispatch("peppol/createPeppolDocument", formData);
			} catch (error) {
				this.$store.commit('displayErrorAlertText', "Error: " + error.message, {root: true})
				this.$store.commit('displayErrorAlert', true, {root: true})
			}
		},

		retrieveInfo() {
			this.clearNotification()
			this.$store.commit('peppolCreateDocumentDisplay', false);
			this.$store.commit('peppolIdentifiersResults', {field: "participantFound", value: false});
			this.$store.commit('peppolIdentifiersResults', {field: "participantNotFound", value: false});
			this.$store.commit('setPeppolRetrievedData', {information: [], identifier: ''});

			if (this.selectedPeppolIdentifier === "Select Peppol identifier" || this.selectedPeppolIdentifier === "No identifiers") {
				this.setError('retrieveInfo', true);
				return
			}


			let identifier = this.selectedPeppolIdentifier;

			if (identifier.includes("-")) {
				identifier = identifier.split("-")[0];
			}

			this.$store.dispatch("peppol/retrievePeppolInfo", identifier);

		},

		updateBool(data, itemName) {
			if (itemName === 'isReceiver') this.inputFieldsPeppolParticipants.isReceiver = data
			else this.inputFieldsPeppolParticipants.isSender = data
		},
		updateInputCountryCode(value) {
			this.inputFieldsPeppolParticipants.countryCode = countryCodes[value].name
		},
		updateDocTypeIdentifier(data) {
			let selected
			if (typeof data === "object") selected = parseInt(data[0])
			else selected = parseInt(data)

			this.selectedDocumentTypeIdentifier = this.documentTypeIdentifiers.find(item => item.id === selected)?.name
			if (this.selectedDocumentTypeIdentifier === undefined) this.selectedDocumentTypeIdentifier = "BIS_BILLING_CREDIT_NOTE_V3"
		},
		updateSelectedPeppolIdentifier(data) {
			this.clearNotification()
			this.setError('retrieveInfo', false);

			if (typeof data === "string") {
				this.selectedPeppolIdentifier = this.peppolIdetifiers.find((item) => item.id.toString() === data).name;
			}

			this.$store.commit('peppolCreateDocumentDisplay', false);
			this.$store.commit('peppolIdentifiersResults', {field: "participantFound", value: false});
			this.$store.commit('peppolIdentifiersResults', {field: "participantNotFound", value: false});
			this.$store.commit('setPeppolRetrievedData', {information: [], identifier: ''});
		},
		async migrateHermesToPeppol() {
			try {
				const splicedSelectedPeppolIdentifier = this.selectedPeppolIdentifier.split('-')
				const res = await this.$store.dispatch('peppol/getHermesMigrationKey', splicedSelectedPeppolIdentifier[0])

				this.displayMigrateToPeppolDialog = true
				this.migrationKey = res
			} catch (err) {
				console.error(err)
				this.$store.commit('displayErrorAlertText', "Error: " + err.message)
				this.$store.commit('displayErrorAlert', true)
			}
		},
		async migrateHermes() {
			if (this.displayMigrateToNymusDialog && this.migrationKey.trim() === '') {
				this.errors.inputMigrationKey = true
				return
			}
			const splicedSelectedPeppolIdentifier = this.selectedPeppolIdentifier.split('-')
			const payload = {
				identifier: splicedSelectedPeppolIdentifier[0],
				migrationKey: this.migrationKey,
				smpProvider: this.selectedSmpProvider,
				name: this.existedPeppolIdentifiers[splicedSelectedPeppolIdentifier[0]].name,
				countryCode: this.existedPeppolIdentifiers[splicedSelectedPeppolIdentifier[0]].countryCode
			}
			try {
				await this.$store.dispatch("peppol/migrateSmp", payload)

			} catch (error) {
				this.$store.commit('displayErrorAlertText', "Error: " + error.message, {root: true})
				this.$store.commit('displayErrorAlert', true, {root: true})
			}

			this.displayMigrateToPeppolDialog = false
			this.displayMigrateToNymusDialog = false
			this.migrationKey = ''
		},
		callModifyPeppolDocument(header, modifyItem) {
			this.header = header
			this.modifyItem = modifyItem
			this.$store.state.displayModifyPeppolDocument = true;
		},
		smpProvider(data) {
			if (typeof data === "object") this.selectedSmpProvider = this.smpProviders.find((item) => item.id === data[0]).name;
			else this.selectedSmpProvider = this.smpProviders.find((item) => item.id.toString() === data).name;
		},
		clearNotification() {
			this.setError("peppolIdentifier", false);
			this.displayNotification = false;
		},
		openConfWindow() {
			this.showConfirm = true;
		},
		clearCache() {
			this.$store.dispatch('globalActions/clearCache')
		},

		deletePeppolIdentifier() {
			delete this.existedPeppolIdentifiers[this.selectedPeppolIdentifier.split('-')[0]]

			let payload = {
				peppolParticipants: {peppolParticipants: {...this.existedPeppolIdentifiers}},
				sgId: this.$store.state.activeSG.supplierGroupId,
				leId: this.$store.state.activeLE.entityNumber,
			}
			this.$store.dispatch('peppol/addPeppolIdentifier', payload);
		},
		checkDelete(data) {
			if (data === "Yes") {
				this.deletePeppolIdentifier();
				this.close()

			} else {
				this.close()
			}
		},

		setError(field, value) {
			this.errors[field] = value
		},
		validateName() {
			this.nameHasError = this.inputFieldsPeppolParticipants.peppolName === '';
			this.peppolIdHasError = this.inputFieldsPeppolParticipants.peppolId === '';
		},
		add() {
			this.validateName()
			this.clearNotification();

			if (this.nameHasError && this.peppolIdHasError) return

			if (this.inputFieldsPeppolParticipants.peppolId === '' || this.inputFieldsPeppolParticipants.peppolName === '') {
				this.setError('peppolIdentifier', true);
				return;
			}

			// let identifier = `${peppolId}-${peppolName}`;
			// Add your validation or processing logic if needed

			let enteredMappedPeppolIdentifier = {
				[this.inputFieldsPeppolParticipants.peppolId.toLowerCase()]: {
					name: this.inputFieldsPeppolParticipants.peppolName,
					isSender: this.inputFieldsPeppolParticipants.isSender,
					isReceiver: this.inputFieldsPeppolParticipants.isReceiver,
					countryCode: this.inputFieldsPeppolParticipants.countryCode
				}
			};

			let peppolIdentifiers = {
				...enteredMappedPeppolIdentifier,
				...this.existedPeppolIdentifiers,
			};

			let payload = {
				peppolParticipants: {peppolParticipants: peppolIdentifiers},
				sgId: this.$store.state.activeSG.supplierGroupId,
				leId: this.$store.state.activeLE.entityNumber,
			};
			this.$store.dispatch('peppol/addPeppolIdentifier', payload);
			this.peppolId = '';
			this.peppolName = '';

			this.inputFieldsPeppolParticipants.countryCode = 'BE'
			this.inputFieldsPeppolParticipants.peppolId = ''
			this.inputFieldsPeppolParticipants.peppolName = ''
			this.inputFieldsPeppolParticipants.isSender = false
			this.inputFieldsPeppolParticipants.isReceiver = false
		},
		close() {
			this.showConfirm = false;
		},
	},
}
</script>

<style scoped>
.centered-label {
	text-align: center;
	padding-bottom: 20px;
	font-weight: bold;
	color: red;
}

.toggle-container {
	display: flex;
	flex-direction: row;
	max-width: 125px;
}

.centered-label-wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.add-button {
	justify-self: start;
	align-self: center;
	padding-right: 200px;
	padding-bottom: 20px;
}

.marginAddBtn {
	margin-top: 10px
}

.retrieve-info {
	display: grid;
	grid-template-rows: 100%;;
	grid-template-columns: 50% 50%;
	grid-column-gap: 10px;
}

.info-container {
	margin-top: 20px;
}

.create-participant, .create-document {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	grid-column-gap: 10px;
	margin-bottom: 10px;
}

.info-row {
	display: grid;
	grid-template-columns: 60% 25% 15%;
	grid-template-rows: auto;
	grid-auto-flow: row;
}

.center {
	align-self: center;
	justify-self: center;
}

.row-item {
	padding: 5px;
	border: 1px solid #d0d0d0;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
}

.table-header {
	background-color: #291f8d;
	color: white;
}

.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
}

.button-container-top {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 120px calc(100% - 120px);
	align-items: center;
	justify-items: end;
}

.button-1 {
	justify-self: start;
}

.button-2 {
	justify-self: end;
}

.input-outer {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 120px calc(100% - 120px);
	align-items: center;
}

.detail-url {
	overflow: auto;
}

.color-blue {
	color: #291f8d;
}
</style>
