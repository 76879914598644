<template>
	<div>
		<div class="flex flex-row" style="margin-bottom: 10px">
			<p class="mr-2 font-bold">Mailing:</p>
			<ToggleSlide
				class="w-min"
				@update-value="mailing = !mailing"
				:bool="mailing"
			/>
		</div>
		<div v-if="mailing" class="ml-4">
			<div class="flex flex-row w-2/3 mb-4">
				<div class="w-full mr-4">
					<p class="mb-1 font-bold">Sending e-mail: *</p>
					<input
						type='email'
						v-model="formData.sendingEmail"
						class="form-control text-popup "
						@focusin="errors.sendingEmail = false"
					/>
					<p v-if="errors.sendingEmail === true && formData.sendingEmail === ''"  class="error">Please provide an email address</p>
					<p v-else-if="errors.sendingEmail === true"  class="error">Email address is incorrect, please provide a correct email address</p>
				</div>


				<div class="w-full">
					<p class="mb-1 font-bold">Display name: *</p>
					<input
						type='text'
						v-model="formData.displayName"
						class="form-control text-popup w-full"
						@focusin="errors.displayName = false"
					/>
					<p v-if="errors.displayName === true"  class="error">Please provide a display name</p>
				</div>

			</div>
			<div class="w-full">
				<FileUpload
					:isLegalEntity="true"
					:legal-entity-name="createdLeEntityNumber"
					@update-value="changeBannerValue"
					accept="image/png, image/jpeg"
					class="w-1/3 pr-2"
					id="banner"
					title="Banner:  *"
					:data="formData.banner"
					:allow-spaces-in-file-name="false"
				/>
				<p v-if="errors.banner === true"  class="error">Please provide a banner</p>

			</div>
			<button
				id="setReporting"
				class="btn mr-4 mb-4 text-white"
				:style="{backgroundColor: setColorSetButton}"
				@click="updateMailTemplate"
			>
				Set Mailing
			</button>
			<button
				v-if="colorSetButton === 'success' "
				id="setReporting"
				class="btn mb-4 text-white"
				:style="{backgroundColor: colors.blue}"
				@click="showDnsRecords = true"
			>
				Get DNS records
			</button>
			<p v-if="errors.mailTemplate === true"  class="error">Couldn't create the mail template, please try again</p>
			<p v-if="errors.dnsRecords === true"  class="error">Couldn't add/get the DNS records</p>

		</div>
		<view-dns-records
			v-if="showDnsRecords"
			:sender-domain="formData.sendingEmail.split('@')[1]"
			:show-pop-up="showDnsRecords"
			@close="showDnsRecords = false"
		/>
	</div>
</template>
<script>
import ToggleSlide from "@/components/ToggleSlide.vue"
import FileUpload from "@/components/FileUpload.vue"
import ViewDnsRecords from "@/components/ViewDnsRecords.vue";

export default {
	components: {ViewDnsRecords, FileUpload, ToggleSlide},
	props: ['colors', 'createdLeEntityNumber', 'createdLeName'],
	data() {
		return {
			colorSetButton: null,
			mailing: false,
			formData: {
				sendingEmail: '',
				displayName: '',
				banner: ''
			},
			errors: {
				sendingEmail: false,
				displayName: false,
				banner: false,
				mailTemplate: false,
				dnsRecords: false
			},
			showDnsRecords: false
		}
	},
	computed: {
		setColorSetButton(){
			if(this.colorSetButton === null) return this.colors.blue
			else if (this.colorSetButton === 'success') return this.colors.success
			else return this.colors.error
		}
	},
	methods: {
		changeBannerValue(event) {
			this.formData.banner = event.value.replaceAll(' ', '_')
			this.setError('banner', false)
		},
		setError(field, value) {
			this.errors[field] = value
		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			return re.test(email);
		},
		async updateMailTemplate() {
			let errors = false

			if (this.formData.banner === '') {
				this.setError('banner', true)
				errors = true
			}
			if (this.formData.displayName === '') {
				this.setError('displayName', true)
				errors = true
			}
			if (this.formData.sendingEmail === '' || !this.validateEmail(this.formData.sendingEmail)) {
				this.setError('sendingEmail', true)
				errors = true
			}

			if (errors) return

			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				leId: this.createdLeEntityNumber,
				edit: "info",
				body: {
					name: this.createdLeName,
					logo: this.formData.banner
				},
			};
			await this.$store.dispatch('EditLegalEntity', payload)

			let mailTemplate = [{
				address: this.createdLeName,
				banner: this.formData.banner,
				docTypes: ['CREDIT_NOTE', 'INVOICE'],
				fromName: this.formData.displayName,
				name: 'MAIL',
				senderEmail: this.formData.sendingEmail,
				version: '1'
			}]

			payload.edit = "mail-template"
			payload.body = {mail: mailTemplate}

			await this.$store.dispatch("EditLegalEntity", payload);

			if (this.$store.state.responseStatus === 200 && this.formData.sendingEmail){
				let resp = await this.$store.dispatch("globalActions/getDnsRecordsPostmark", this.formData.sendingEmail.split('@')[1])
				if (resp.status === 404){
					let respCreate = await this.$store.dispatch("globalActions/createDnsRecordsPostmark", this.formData.sendingEmail.split('@')[1])
					if (respCreate.status !== 200) {
						this.colorSetButton = "error"
						console.error(resp)
					} else {
						this.colorSetButton = "success"
						this.showDnsRecords = true
					}
				} else {
					this.colorSetButton = "success"
					this.showDnsRecords = true
				}
			}
			else this.colorSetButton = "error"

		}
	}
}
</script>