import axios from "axios"

export default {
	sendMassResendPostmark(context, payload) {
		axios.post(context.rootState.baseUrl + context.rootState.API.massResendPostmark, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch(err => console.error(err))
	},
	async syncDnsRecordsPostmark(context) {
		await axios.get(context.rootState.baseUrl + context.rootState.API.syncDnsRecordsPostmark)
			.then(_ => {
				context.commit("toggleAlert", {
					title: "Sync DNS records",
					text: "DNS records are synced",
				}, {root: true})
			}).catch(err => {
				console.error(err)
				context.commit('displayErrorAlert', true, {root: true})
			})
	},
	async getDnsRecordsPostmark(context, payload) {
		return await axios.get(context.rootState.baseUrl + context.rootState.API.getDnsRecordsPostmark + payload)
			.catch(err => {
			return err
		})
	},
	async createDnsRecordsPostmark(context, payload) {
		return await axios.post(context.rootState.baseUrl + context.rootState.API.createDnsRecordsPostmark, {name: payload}, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			}
		}).catch(err => {
			return err
		})
	},

	/**
	 * Global overview
	 * ACTIONS TAB
	 * Restore SG users
	 */
	RestoreSGUsers(context, payload) {
		axios.request({
			url: "api/admin/supplier-groups/" + payload + "/restore-users",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		}).then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("RestoreSGUsers-error: ", exception)
			})
	}
	,
	RestoreAllUsers(context) {
		axios.request({
			url: "api/admin/restore-all-users",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("RestoreAllUsers-error: ", exception)
			})
	}
	,
	ClearAllOrphanedMessages(context) {
		axios.request({
			url: "api/admin/clear-messages",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("RestoreAllUsers-error: ", exception)
			})
	}
	,
	ClearSGOrphanedMessages(context, payload) {
		axios.request({
			url: "api/admin/supplier-groups/" + payload + "/clear-messages",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("RestoreAllUsers-error: ", exception)
			})
	}
	,
	ArchiveDocuments(context, payload) {
		axios.request({
			url: "api/admin/supplier-groups/" + payload.sgId + "/archive/start",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: payload.body
		})
			.then(() => {
				context.rootState.responseStatus = 200
				context.commit("startArchive", true, {root: true})
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("ArchiveDocuments-error: ", exception)
			})
	}
	,
	triggerInvoicePolling(context, payload) {
		axios.request({
			url: "api/admin/invoices-feed/poll",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: payload
		})
			.then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("ArchiveDocuments-error: ", exception)
			})
	}
	,
	triggerTransactionPolling(context, payload) {
		axios.request({
			url: "api/admin/transactions-feed/poll",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: payload
		})
			.then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("ArchiveDocuments-error: ", exception)
			})
	}
	,
	triggerMandatePolling(context, payload) {
		axios.request({
			url: "api/admin/mandates-feed/poll",
			method: "post",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
			data: payload
		}).then(() => context.rootState.responseStatus = 200)
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("ArchiveDocuments-error: ", exception)
			})
	}
	,
	clearCache() {
		let url = ''
		if (window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')) {
			url = 'https://staging-connect.nymus.eu/v1/gateway/system/cache'
		} else {
			url = 'https://connect.nymus.eu/v1/gateway/system/cache'
		}
		axios.delete(url)
	}
	,
	clearCash(context, payload) {
		let url = context.rootState.baseUrl + "api/system/" + payload

		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit("toggleAlert", {
				title: "Clear cash",
				text: payload + " cash cleared successfully",
			}, {root: true})
		})
			.catch(() => context.commit('displayErrorAlert', true, {root: true}))
	}
	,

	triggerPostDropIngestion(context) {
		let url = context.rootState.baseUrl + context.rootState.API.triggerPostDropIngestion
		return axios.post(url, {}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit("toggleAlert", {
				title: "PostDrop ingestion",
				text: "PostDrop ingestion successfully triggered",
			}, {root: true})
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
		})
	}
}