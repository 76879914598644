<template>
    <div class="mb-3">
        <p v-if="title" class="font-bold form-label mr-2">{{ title }}</p>
        <div
              :class="{ 'border border-primary-3': invalid }"
              class="flex flex-row input-group w-full"
        >
            <div v-if="prefix" class="input-group-text">
                {{ prefixData }}
            </div>
            <input disabled type="text" class="form-control" :value="data"/>
            <!--UPDATE SUFFIX-->
            <div class="font-bold cursor-pointer input-group-text" :class="{'display-none' : uploadIconNotDisplay}">
                <label :for="id" class="cursor-pointer">
                    <font-awesome-icon icon="upload"/>
                </label>
                <input
                      type="file"
                      class="hidden"
                      :id="id"
                      @change="updateData($event.target.files, $event.target.value)"
                      :accept="accept"
                />
            </div>
            <!--DELETE SUFFIX-->

            <div
                  v-if="delSuffix"
                  @click="deleteData(data)"
                  class="font-bold cursor-pointer input-group-text"
            >
                <font-awesome-icon icon="trash-can"/>
            </div>
			<div
				v-if="downloadSuffix"
				class="font-bold cursor-pointer input-group-text"
			>
				<span class="material-icons" style="font-size: 20px">file_download</span>
			</div>

        </div>
    </div>
</template>
<script>
export default {
    name: "FileUpload",
    props: {
        prefix: Boolean,
        prefixData: String,
        delSuffix: Boolean,
		downloadSuffix: {
			type: Boolean,
			required: false,
			default: false
		},
        invalid: Boolean,
        id: String,
        title: String,
        data: [String, Object, Array],
        accept: String,
        isLegalEntity: Boolean,
		legalEntityName: {
			type: String,
			required: false,
			default: null
		},
        isSpeosReprocess: Boolean,
        isForSftpMount : {
            type: Boolean,
            default: false,
        },
        processFailedDocType:{
          type : String,
          default: ''
        },
        uploadIconNotDisplay: {
            type: Boolean,
            default: false,
        },
		allowSpacesInFileName : {
			type: Boolean,
			required: false,
			default: true
		}
    },
    emits: ["update-value", "delete-value"],
    methods: {
        async updateData(files, value) {
            this.$store.state.tempFile = "";
			if (!this.allowSpacesInFileName) {
				let renamedFiles = []
				files.forEach(file => {
					let f = new File([file], file.name.replaceAll(' ', '_'), {type: file.type})
					renamedFiles.push(f)
					// file.name = file.name.replaceAll(' ', '_')
				})
				files = renamedFiles
			}

            let payload = {
                files: files,
                value: value.replace(/C:\\fakepath\\/, ""),
            };

            if (this.isSpeosReprocess) {
                let file = new FormData();
                if (
                      window.location.hostname.includes("localhost") ||
                      window.location.hostname.includes("staging")
                ) {
                    file.append("bucketName", "optipost-printing-staging");
                    this.$store.commit('setBucketName', "optipost-printing-staging");
                } else {
                    file.append("bucketName", "optipost-printing-production");
                    this.$store.commit('setBucketName', "optipost-printing-production");
                }
                file.append("file", files[0]);
                file.append("bucketFolder", "manualfeedback");
                await this.$store.dispatch("file/uploadFile", file);
                this.$emit("update-value", payload);
            } else {
                if (payload.value !== "") {
                    if(!this.isForSftpMount){
                        let file = new FormData();
                        file.append(
                              "bucketName",
                              this.$store.state.activeSG.storageConfig.bucketConfig.branding
                        );
                        file.append("file", files[0]);
                        this.$store.commit('setBucketName', this.$store.state.activeSG.storageConfig.bucketConfig.branding);
                        if (this.isLegalEntity) {
							if (this.$store.state.activeLE?.entityNumber){
								file.append(
									"bucketFolder",
									this.$store.state.activeLE.entityNumber
								);
							} else if(this.legalEntityName) {
								file.append(
									"bucketFolder",
									this.legalEntityName
								);
							}
                        }

                        await this.$store.dispatch("file/uploadFile", file);
                        this.$store.commit("setUploadedFileName", files[0].name);

                        let data = {
                            file: files[0].name,
                            supplierGroup: this.$store.state.activeSG.supplierGroupId,
                        };
                        await this.$store.dispatch("file/getProxyFile", data);
                        this.$emit("update-value", payload);
                    } else {
                        let file = new FormData();
                        let requestData = {
                            type : this.processFailedDocType,
                            file: file,
                        };

                        let bucketName = this.$store.state.activeSG.storageConfig.bucketConfig.sftpMount
                        let bucketFolder = 'outgoing/data'

                        if (this.processFailedDocType === 'attachment'){
                            bucketFolder = 'outgoing/attachments'
                        }

                        if(this.processFailedDocType === 'xml'){
                            this.$store.commit("setUploadedFileName", files[0].name);
                        }

                        file.append("bucketName", bucketName);
                        file.append("bucketFolder", bucketFolder);
                        file.append("file", files[0]);

                        this.$store.dispatch("file/uploadFileSftpMount", requestData);
                        this.$emit("update-value", payload);


                    }

                }
            }
        },
        deleteData(data) {
            this.$emit("delete-value", data);
        },
    },
};
</script>
<style scoped>
.display-none {
    display: none;
}


</style>
