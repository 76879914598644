<template>
	<pop-up-window
		:show="this.$store.state.displayEditQRTemplate &&!this.$store.state.displayAddQrText"
		:showClose="false"
		:blur="showConfirm"
		@close="close"
		class="doc-statuses-popup edit-qr"
		fixed
	>
		<template #header>Edit QR Template</template>
		<template #content>
			<div v-if="displayTemplate">
				<div class=" w-1/2 pr-6 mb-2">
					<label for="qrTemplateName" class="font-bold form-label">
						Select QR template:
					</label>
					<Dropdown
						id="qrTemplateName"
						@update-value="updateSelectedName"
						:class="{ 'border border-primary-3': selectedOverlayInvalid }"
						:data="qrTemplatesNames"
						:selected="this.$store.state.selectedQrTemplateName"
					/>
				</div>

				<div
					:class="{ 'pointer-events-none': showConfirm }"
					class="flex flex-row flex-nowrap"
				>

					<div class="flex flex-col w-1/2 mr-12" v-if="showQrTemplate">
						<!--QR Overlay-->
						<div class="mb-3">
							<label for="editQrOverlay" class="font-bold form-label">QR overlay mode:*</label>
							<Dropdown
								id="editQrOverlay"
								:class="{ 'border border-primary-3': selectedOverlayInvalid }"
								@update-value="updateSelectedQrOverlay"
								:data="qrOverlayData1"
								:selected="activeTemplate.overlayMode"
							/>
						</div>
						<span v-if="selectedOverlayInvalid" class="text-primary-3">
                        Select an overlay mode
                    </span>
						<div class="mb-3" v-if="displayOffset">
							<label for="editQrOverlay" class="font-bold form-label">
								QR offset:*
							</label>
							<Dropdown
								id="editQrOffset"
								:class="{ 'border border-primary-3': selectedOverlayInvalid }"
								@update-value="updateSelectedQrOffset"
								:data="qrOffsetData"
								:selected="activeTemplate.offset"
							/>
						</div>

						<label for="qrTemplateDocTypes" class="font-bold form-label">
							QR doc types:*
						</label>
						<DropdownSelectAll
							v-if="renderComponent"
							id="qrTemplateDocTypes"
							:class="{ 'border border-primary-3': selectedOverlayInvalid }"
							@update-value="updateSelectedDocTypes"
							:data="qrDocTypes"
							:selected=activeTemplate.docTypes
						/>
						<span v-if="docTypesError" class="text-primary-3">
                        Please, select at least one doc type
                    </span>
						<label for="qrTemplateReason" class="font-bold form-label">
							QR template reason:*
						</label>

						<Dropdown
							id="qrTemplateReason"
							@update-value="updateSelectedReason"
							:class="{ 'border border-primary-3': selectedOverlayInvalid }"
							class="w-1/2"
							:data="qrTemplatesReason"
							:selected="activeTemplate.reason"
						/>
						<span v-if="reasonError" class="text-primary-3">
                        Please, select reason
                    </span>

						<!--Width X/Y Coords-->
						<div>
							<div class="label-with-mark">
								<div class="color-mark bg-red"></div>
								<h2 class="label-style mt-2">QR position*:</h2>
							</div>
							<div class="flex flex-row ml-6 mt-2">
								<div class="flex flex-col">
									<Field
										id="qrTemplateWidth"
										type="number"
										:hasError="widthInvalid"

										@input="validate"
										title="Width: *"
										v-model.number="activeTemplate.width"
										:numberIntervals="0.5"
									/>
									<span v-if="widthInvalid" class="text-primary-3">{{widthErrorText }}</span>
								</div>
								<Field
									id="qrTemplateXCoord"
									type="number"
									title="X-coordinate: *"
									v-model="activeTemplate.xCoordinate"
									:numberIntervals="0.5"
								/>
								<Field
									id="qrTemplateYCoord"
									type="number"
									title="Y-coordinate: *"
									v-model="activeTemplate.yCoordinate"
									:numberIntervals="0.5"
								/>
							</div>
						</div>

						<!-- Link position-->
						<div>
							<div class="mb-3 flex">
								<div class="label-with-mark">
									<div class="color-mark bg-blue"></div>
									<h2 class="label-style mt-2 mr-2">Add link:</h2>
								</div>
								<ToggleSlide
									class="w-min"
									id="addFontFile"
									@update-value="updateAddLink"
									:key="addLink"
									:bool="addLink"
								/>
							</div>

							<div v-if="addLink" class="ml-6">
								<div class="grid grid-cols-3">
									<Field
										id="linkTemplateXCoord"
										type="number"
										title="X-coordinate: *"
										v-model="activeTemplate.documentParameter.xCoordinate"
										:numberIntervals="0.5"
									/>
									<Field
										id="linkTemplateYCoord"
										type="number"
										title="Y-coordinate: *"
										v-model="activeTemplate.documentParameter.yCoordinate"
										:numberIntervals="0.5"
									/>
									<Field
										id="linkTemplateFontSize"
										type="number"
										title="Font size*: "
										v-model="activeTemplate.documentParameter.fontSize"
										:numberIntervals="1"
										@change="setLinkWidth"
									/>
								</div>
								<div class="check-link-text">
									<span ref="linkWidth"
										:style="{fontSize: activeTemplate.documentParameter.fontSize + 'px'}">
										{{ linkText }}
									</span>
									<Field
										id="linkTemplateYCoord"
										type="text"
										title="Check the link text width (is NOT sent to BE, only to visualize the width): "
										v-model="linkText"
										@keyup="setLinkWidth"
										class="check-link-input"
									/>
								</div>

								<Dropdown
									id="fieldLink"
									@update-value="updateLinkField"
									:class="{ 'border border-primary-3': selectedOverlayInvalid }"
									class=""
									:data="linkField"
									:selected="activeTemplate.documentParameter.field"
								/>
								<div class="mt-2">
									<FileUpload
										:isLegalEntity="isLegalEntity"
										@update-value="updateFontFileValue"
										@delete-value="deleteFontFileValue"
										:data="activeTemplate.documentParameter.font.fileName"
										:delSuffix="true"
										id="AddFontFile"
										title="Upload font file*:"

									/>
									<p v-if="fontError" class="text-primary-3"> Please, select the font file</p>
								</div>
							</div>
						</div>

						<!-- Images -> Width/X/Y-->
						<div class="flex mb-1 mt-2">
							<div class="label-with-mark">
								<div class="color-mark bg-green"></div>
								<h2 class="label-style mt-2 mr-2">QR images:</h2>
							</div>
							<button @click="showAddFileDialog = true"
								class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
							>
								Add
							</button>
							<add-file-to-template
								title-dialog="Add QR image"
								file-type="image/png, image/jpeg"
								:show-dialog="showAddFileDialog"
								:lang-code="langCode"
								:is-legal-entity="isLegalEntity"
								:position="test"
								@add-file="addQrImage"
								@cancel-add-file="showAddFileDialog = false"
							/>
						</div>

						<div class="flex flex-row ml-6">
							<div class="flex flex-col">
								<Field
									max=100
									id="qrTemplateImagesWidth"
									type="number"
									@input="scaleImg"
									title="Width: *"
									:numberIntervals="0.5"
									v-model.number="test.width"
									@click="clearError"
								/>
								<span v-if="widthInvalidQrImg" class="text-primary-3">
									{{ widthErrorTextQrImg }}
								</span>

							</div>

							<Field
								id="qrTemplateImagesXCoord"
								type="number"
								title="X-coordinate: *"
								v-model="test.xCoord"
								:numberIntervals="0.5"
							/>
							<Field
								id="qrTemplateImagesYCoord"
								type="number"
								title="Y-coordinate: *"
								v-model="test.yCoord"
								:numberIntervals="0.5"
							/>
						</div>

						<!--Images-->
						<div v-if="test.qrImages" class="ml-6">
							<div
								:key="index + item"
								v-for="(item, index) in Object.keys(test.qrImages)"
								class="flex flex-row"
							>
								<div class="input-group mb-1 w-full">
									<div class="input-group-text">
										{{ item }}
									</div>
									<input
										type="text"
										class="form-control"
										v-model="test.qrImages[item].img"
									/>

									<div
										@click="deleteImage(item)"
										class="font-bold cursor-pointer input-group-text"
									>
										<font-awesome-icon icon="trash-can"/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex flex-col w-1/2" v-if="showQrTemplate">
						<FileUpload
							:isLegalEntity="isLegalEntity"
							@update-value="updateFileValue"
							@delete-value="deleteFileValue"
							:data="activeTemplate.file"
							:delSuffix="true"
							id="editQrFileUploadPreview"
							title="Upload QR Preview:"
							accept=".pdf"
						/>
						<div  class="full-size mx-auto relative border-4">
							<div
								ref="boundary"
								@mousedown="handleMouseDown"
								@mousemove="handleMouseMove"
								@mouseup="handleMouseUp"
							>
								<div
									ref="draggable"
									class="makeSquare cursor-move absolute bg-primary-3 z-150"
									:style="{bottom: activeTemplate.yCoordinate + '%',
									left: activeTemplate.xCoordinate + '%',
									width: activeTemplate.width + '%', }"
								>
								</div>
								<div
									v-if="addLink"
									ref="draggableLink"
									class="cursor-move absolute bg-blue z-150 grid-div"
									:style="{bottom: activeTemplate.documentParameter.yCoordinate + '%',
									left: activeTemplate.documentParameter.xCoordinate + '%',
									width: this.linkWidth + '%',
									height: linkHeight + '%'}">
								</div>
								<div
									ref="draggableImage"
									class="qRImage  make-transparent cursor-move absolute z-20"
									:style="{ bottom: test.yCoord + '%', left: test.xCoord + '%',width: test.width + '%', height: test.height + '%',
									'background-image': 'url(' + qrBGImgUrl + ')'}"
								></div>
								<div ref="pdfContainer" v-if="!activeTemplate.file.endsWith('/proxy/')">
									<VuePdf
										class="w-full"
										:src="activeTemplate.file"
										:page="1"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					:class="{ 'pointer-events-none': showConfirm }"
					class="grid grid-rows-1 justify-items-end"
				>
					<div class="mt-2">
						<button class="btn shadow-md mr-2" @click="update"
								v-if="showQrTemplate"
						>
							Update
						</button>
						<button
							v-if="showQrTemplate"
							class="btn shadow-md bg-theme-1 text-white mr-2"
							@click="showConfirmDialog"
						>
							Delete
						</button>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
					</div>
				</div>
			</div>
			<div v-if="!displayTemplate">
				No template yet
				<div class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="delTemp" :show="showConfirm"/>
	<AddLinkFontFile
		:isLegalEntity="isLegalEntity"
		@add-linkFontFile="addFile"
		:langCode="langCodes"
	/>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Field from "../../Field.vue";
import FileUpload from "../../FileUpload.vue";
import Dropdown from "../../Dropdown.vue";
import ConfirmDialog from "../../ConfirmDialog.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import AddLinkFontFile from "./AddLinkFontFile.vue";
import langCodes from "../../../langCodes";
import ToggleSlide from "../../ToggleSlide.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";
import {VuePdf} from "vue3-pdfjs";

export default {
	name: "EditQrText",
	components: {
		VuePdf,
		AddFileToTemplate,
		PopUpWindow,
		Field,
		FileUpload,
		Dropdown,
		ConfirmDialog,
		DropdownSelectAll,
		AddLinkFontFile,
		ToggleSlide
	},
	props: {
		qrOverlayData: Array,
		langCode: Array,
		isFor: String,
	},
	data() {
		return {
			addLink: false,
			langCodes: langCodes,
			isLegalEntity: this.isFor === "legalEntity",
			showConfirm: false,
			supplierGroup: this.$store.state.activeSG,
			legalEntity: this.$store.state.activeLE,

			activeItem: {},
			formData: {
				qr: {},
			},
			test: {
				width: 0,
				height: 0,
				xCoord: 0,
				yCoord: 0,
				qrImages: {}
			},
			selectedOverlayInvalid: false,
			widthErrorText: "",
			widthErrorTextImg: "",
			widthInvalidQrImg: false,
			widthInvalid: false,
			fontError : false,
			isDragging: false,
			isDraggingLink: false,
			isDraggingImage: false,
			qrOffsetData: this.$store.state.offsetData,
			displayOffset: false,
			qrOverlayData1: this.$store.state.overlayData,
			linkField: this.$store.state.linkField,

			qrTemplatesData: [],
			qrTemplatesNames: [
				{id: 0, name: "no templates yet"},
			],
			qrTemplatesReason: [...this.$store.state.templateReason, {id: 4, name: "No template reason"}],
			activeTemplate: {},
			initialSelectedTemplateName: '',
			selectedTemplateName: '',
			qrDocTypes: this.$store.state.docTypes,
			renderComponent: true,
			docTypesError: false,
			reasonError: false,

			linkText: 'XXXXXXX',
			linkWidth: 8,
			// displayLinkFontSize : 4
			qrBGImgUrl : '',
			qrWidth : 0,

			showAddFileDialog: false
		}
	},
	mounted() {
		this.initData();
	},
	computed: {
		displayTemplate() {
			if (this.isFor === "legalEntity") {
				return this.$store.state.activeLE.templates ? this.$store.state.activeLE.templates.qrTemplates ? true : false : false
			}
			if (this.isFor === "supplierGroup") {
				return this.$store.state.activeSG.templates ? this.$store.state.activeSG.templates.qrTemplates ? true : false : false
			}
			return true
		},
		linkHeight() {
			//  1px = 2.54 cm / 96 = 0.0264cm
			//   the height of linkLine this.activeTemplate.documentParameter.font * 0.0264cm
			//the height of linkLine relatively to page height  ((this.activeTemplate.documentParameter.fontSize * 0.0264cm)/29.7)*100
			return ((this.activeTemplate.documentParameter.fontSize * 0.0264) / 29.7) * 100
		},

		activeSgData() {
			return this.$store.state.activeSG;
		},
		activeLeData() {
			return this.$store.state.activeLE;
		},
		showQrTemplate() {
			return !!this.activeTemplate.name;
		},

		templateExist() {
			if (this.isFor === "legalEntity") {
				return this.$store.state.activeLE.templates ? !!this.$store.state.activeLE.templates.qr : false
			}
			return this.$store.state.activeSG.templates ? !!this.$store.state.activeSG.templates.qr : false
		},
	},
	watch: {
		activeSgData(newVal) {
			if (newVal.templates && newVal.templates.qrTemplates) {
				this.qrTemplatesData = newVal.templates.qrTemplates
			}
			if (newVal.templates && newVal.templates.qrTemplates === undefined) {
				this.qrTemplatesData = [];
			}
			this.updateQrTemplatesNames();
		},
		activeLeData(newVal) {
			if (newVal?.templates?.qrTemplates === undefined) {
				this.qrTemplatesData = [];
			}
			else {
				this.qrTemplatesData = newVal.templates.qrTemplates
			}

			this.updateQrTemplatesNames();
		}
	},
	methods: {
		setLinkWidth() {
			//  1px = 2.54 cm / 96 = 0.0264cm
			//   the width of this.$refs.linkWidth.offsetWidth * 0.0264cm
			//the height of linkLine relatively to page height  ((this.$refs.linkWidth.offsetWidth * 0.0264cm)/21)*100
			if (this.linkText !== '') {
				this.linkWidth = (this.$refs.linkWidth.offsetWidth * 0.0264 * 100) / 21;
				//  this.displayLinkFontSize = Math.round((this.activeTemplate.documentParameter.fontSize *300* 0.0264/21));

			} else {
				this.linkWidth = 2;
				//   this.displayLinkFontSize = 4
			}
		},
		updateAddLink(data) {
			this.addLink = data;
		},
		updateFile(event, data) {
			this.activeTemplate.documentParameter.font[data].fileName = event.value;
			this.activeTemplate.documentParameter.font[data].url = this.tempFile;
		},
		deleteFile(event, data) {
			delete this.activeTemplate.documentParameter.font[data];
		},
		addFile(data) {
			let addedFile = data;

			for (let lang in addedFile) {
				addedFile[lang].path = addedFile[lang].url
			}

			this.activeTemplate.documentParameter.font = {
				...this.activeTemplate.documentParameter.font,
				...addedFile,
			};
		},
		displayAddLinkFontFile() {
			this.$store.state.displayAddLinkFontFile = true;
		},
		updateQrTemplatesNames() {
			let names = [{id: 0, name: ""}];
			this.qrTemplatesData.forEach((template, index) => {
				names.push(
					{
						id: index + 1,
						name: template.name
					})
			});
			this.qrTemplatesNames = names
		},
		updateSelectedReason(data) {
			this.reasonError = false;
			this.activeTemplate.reason = this.qrTemplatesReason[data].name;
		},
		updateSelectedDocTypes(data) {
			this.docTypesError = false;

			let foundArr = [];
			for (let index of data) {
				let found = this.qrDocTypes[index].name;
				foundArr.push(found);
			}
			this.activeTemplate.docTypes = foundArr;
		},

		updateSelectedName(selectedTemplateId) {
			if (this.qrTemplatesNames.length === 0) {
				this.activeTemplate = {};
				this.selectedTemplateName = "";
				return;
			}

			if (typeof selectedTemplateId === "object"){
				selectedTemplateId = selectedTemplateId[0]
			}

			this.selectedTemplateName = this.qrTemplatesNames.find(item => (item.id.toString() === selectedTemplateId.toString()))?.name;
			let foundTemplate = {};
			this.qrTemplatesData.forEach(template => {
				if (template.name === this.selectedTemplateName) {
					foundTemplate = template;
				}
			});
			this.activeTemplate = JSON.parse(JSON.stringify(foundTemplate));

			if (!this.activeTemplate.documentParameter) {
				this.activeTemplate.documentParameter = {
					xCoordinate: 0,
					yCoordinate: 50,
					width: this.linkWidth,
					fontSize: 14,
					field: "CHANNEL_CHOICE_ACCESS_CODE",
					font: {
						bucketName: '',
						fileName: ''
					}
				}
				this.addLink = false;
			} else {
				this.activeTemplate.documentParameter.width = this.linkWidth;
				if (!this.activeTemplate.documentParameter.font) {
					this.activeTemplate.documentParameter.font = {
						bucketName: '',
						fileName: ''
					}
				}
				this.addLink = true;
			}

			if (this.activeTemplate
				&& this.activeTemplate
				&& this.activeTemplate.background
				&& this.activeTemplate && this.activeTemplate.background.location
				&& Object.keys(this.activeTemplate && this.activeTemplate.background.location).lenghth !== 0
			) {
				this.$store.state.allQrTemplateDeleted = false;
			}

			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			} else {
				this.activeItem = this.$store.state.activeLE;
			}
			if (this.activeTemplate && this.activeTemplate.background) {

				this.$store.state.qrImageSize.width = this.activeTemplate.background.width;
				this.$store.state.qrImageSize.height = this.activeTemplate.background.height;
				this.test.width = this.activeTemplate.background.width;
				this.test.height = this.activeTemplate.background.height;

				this.test.xCoord = this.activeTemplate.background.xCoordinate;
				this.test.yCoord = this.activeTemplate.background.yCoordinate;

				if (this.activeTemplate.background.location) {
					this.test.qrImages = {}
					for (const item in this.activeTemplate.background.location) {

						this.test.qrImages[item] = {
							url : this.activeTemplate.background.location[item].url,
							img: this.activeTemplate.background.location[item].fileName,
						}
					}
				}

			} else {
				this.$store.state.qrImageSize.width = 0;
				this.$store.state.qrImageSize.height = 0;
				this.test.width = 0;
				this.test.xCoord = 0;
				this.test.yCoord = 0;
				this.test.qrImages = {}
			}

			if (this.activeTemplate.background && this.activeTemplate.background.location) {
				if (Object.values(this.activeTemplate.background.location)[0].url) {
					this.qrBGImgUrl = Object.values(this.activeTemplate.background.location)[0].url;
				}
			}
			this.forceRerender();
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		clearError() {
			this.widthInvalidQrImg = false;
		},
		initData() {
			this.fontError = false;

			this.reasonError = false;
			this.docTypesError = false;
			(this.supplierGroup = this.$store.state.activeSG),
				(this.legalEntity = this.$store.state.activeLE),
				(this.showConfirm = false);
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}
			(this.isLegalEntity = this.isFor === "legalEntity"),
				(this.widthErrorText = "");
			this.selectedOverlayInvalid = false;

			if (this.activeTemplate.offset) {
				this.displayOffset = true
			}

			if (this.activeItem.templates && this.activeItem.templates.qrTemplates) {
				this.qrTemplatesData = this.activeItem.templates.qrTemplates
			}
			if (this.activeItem.templates && this.activeItem.templates.qr) {
				this.qrTemplatesData = [this.activeItem.templates.qr];
			}

			this.qrTemplatesData.forEach(template => {
				if (template.docTypes === undefined) {
					template.docTypes = []
				}
				if (template.reason === undefined) {
					template.reason = ''
				}
			})

			let names = [];
			this.qrTemplatesData.forEach((template, index) => {
				names.push(
					{
						id: index,
						name: template.name
					})
			})
			this.qrTemplatesNames = names;

			if (this.qrTemplatesNames[0] !== undefined){
				this.initialSelectedTemplateName = this.qrTemplatesNames[0].name;
			}

			this.$store.state.qrImageSize.width = 0;
			this.$store.state.qrImageSize.height = 0;
			this.test.width = 0;
			this.test.xCoord = 0;
			this.test.yCoord = 0;
			this.test.qrImages = {}
			this.activeTemplate = {};
			this.selectedTemplateName = '';

			this.showQrTemplate = false;
			this.linkText = 'XXXXXXX';
			this.linkWidth = 8;
		},
		updateFontFileValue() {
			setTimeout(() => {
				this.activeTemplate.documentParameter.font = {
					url: this.$store.state.tempFile,
					bucketName: this.$store.state.bucketName,
					fileName: this.$store.state.tempFileName
				}
				this.fontError = false;
			}, 1500);
		},
		async updateFileValue() {
			setTimeout(() => {
				this.activeTemplate.file = this.$store.state.tempFile
			}, 1000);

		},
		deleteFontFileValue() {
			this.activeTemplate.documentParameter.font = {
				url: '',
				bucketName: '',
				fileName: ''
			};
		},
		deleteFileValue() {
			delete this.activeTemplate.file
		},
		updateSelectedQrOffset(data) {
			this.activeTemplate.offset = this.qrOffsetData[data].name
		},
		updateLinkField(data) {
			this.activeTemplate.documentParameter.field = this.linkField[data].name
		},
		updateSelectedQrOverlay(data) {
			this.activeTemplate.overlayMode = this.qrOverlayData1[data].name;
			if (this.qrOverlayData1[data].name === "OFFSET") {
				this.displayOffset = true;

				if (this.activeTemplate.offset === "" || this.activeTemplate.offset === undefined) {
					this.activeTemplate.offset = parseInt(this.qrOffsetData[0].name);
				}
			} else {
				this.displayOffset = false
			}
			this.validate()
		},
		addQrText(data) {
			this.activeTemplate.text = {...this.activeTemplate.text, ...data};
		},
		displayAddQrText() {
			this.$store.state.displayAddQrText = true;
		},
		deleteText(data) {
			delete this.activeTemplate.text[data];
		},
		//QR Text
		clamp(num, lower = 0, upper) {
			return num < lower ? lower : num > upper ? upper : num;
		},
		clampInsideBoundary(x, y) {
			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect();
			this.$store.state.qrBoundary.width = boundaryPos.width
			this.$store.state.qrBoundary.height = boundaryPos.height
			let heightBoundaryPercentage, widthBoundaryPercentage, maxWidth, maxHeight
			if (this.isDragging) {
				heightBoundaryPercentage = 100 - (this.$refs.draggable.clientHeight / boundaryPos.height) * 100;
				widthBoundaryPercentage = 100 - this.activeTemplate.width;
				maxWidth = boundaryPos.width - this.activeTemplate.width;
				maxHeight = boundaryPos.height - this.$refs.draggable.clientHeight;
			}
			if (this.isDraggingLink) {
				heightBoundaryPercentage = 100 - (this.$refs.draggableLink.clientHeight / boundaryPos.height) * 100;
				widthBoundaryPercentage = 100 - this.activeTemplate.documentParameter.width;
				maxWidth = boundaryPos.width - this.activeTemplate.documentParameter.width;
				maxHeight = boundaryPos.height - this.$refs.draggableLink.clientHeight;
			}
			if (this.isDraggingImage) {
				heightBoundaryPercentage = 100 - (this.$refs.draggableImage.clientHeight / boundaryPos.height) * 100;
				widthBoundaryPercentage = 100 - this.test.width;
				maxWidth = boundaryPos.width - this.test.width;
				maxHeight = boundaryPos.height - this.$refs.draggableImage.clientHeight;
			}

			let cx = (this.clamp(x - boundaryPos.x, 0, maxWidth) / maxWidth) * 100;
			let cy = ((maxHeight - this.clamp(y - boundaryPos.y, 0, maxHeight)) / maxHeight) * 100;
			cx = cx > widthBoundaryPercentage ? widthBoundaryPercentage : cx;
			cy = cy > heightBoundaryPercentage ? heightBoundaryPercentage : cy;
			if (this.isDragging) {
				this.activeTemplate.xCoordinate = Math.round(cx);
				this.activeTemplate.yCoordinate = Math.round(cy);
			}
			if (this.isDraggingLink) {
				this.activeTemplate.documentParameter.xCoordinate = Math.round(cx);
				this.activeTemplate.documentParameter.yCoordinate = Math.round(cy);
			}
			if (this.isDraggingImage) {
				this.test.xCoord = Math.round(cx);
				this.test.yCoord = Math.round(cy);
			}

			return {x: cx, y: cy,}
		},
		findPositionInsideBound(e) {
			let x, y
			if (this.isDragging) {
				x = e.clientX - this.$refs.draggable.clientWidth / 2;
				y = e.clientY - this.$refs.draggable.clientHeight / 2;
			}
			if (this.isDraggingLink) {
				x = e.clientX - this.$refs.draggableLink.clientWidth / 2;
				y = e.clientY - this.$refs.draggableLink.clientHeight / 2;
			}
			if (this.isDraggingImage) {
				x = e.clientX - this.$refs.draggableImage.clientWidth / 2;
				y = e.clientY - this.$refs.draggableImage.clientHeight / 2;
			}
			this.validate()
			return this.clampInsideBoundary(x, y);
		},
		handleMouseDown(e) {
			if (e.target === this.$refs.draggable) {
				this.isDragging = true;
			}
			if (e.target === this.$refs.draggableLink) {
				this.isDraggingLink = true;
			}
			if (e.target === this.$refs.draggableImage) {
				this.isDraggingImage = true
			}
		},
		handleMouseMove(e) {
			if (this.isDragging) {
				const position = this.findPositionInsideBound(e);

				this.activeTemplate.xCoordinate = Math.round(position.x);
				this.activeTemplate.yCoordinate = Math.round(position.y);
			}
			if (this.isDraggingLink) {
				const position = this.findPositionInsideBound(e);

				this.activeTemplate.documentParameter.xCoordinate = Math.round(position.x);
				this.activeTemplate.documentParameter.yCoordinate = Math.round(position.y);
			}
			if (this.isDraggingImage) {
				const position = this.findPositionInsideBound(e);

				this.test.xCoord = Math.round(position.x);
				this.test.yCoord = Math.round(position.y);
			}
		},
		handleMouseUp() {
			this.isDragging = false;
			this.isDraggingLink = false;
			this.isDraggingImage = false;
		},
		//QR Image
		getImageSize() {

			if (Object.keys(this.test.qrImages).length > 0
				&& this.activeTemplate
				&& !this.activeTemplate.background
				&& this.test.width === 0) {

				let imageWidth;
				let imageHeight;

				imageWidth = this.$store.state.qrImageSize.width;
				imageHeight = this.$store.state.qrImageSize.height;

				this.test.width = (imageWidth / this.$store.state.qrBoundary.width) * (100 / 2);
				let ratio = this.test.width / imageWidth;
				this.test.height = (imageHeight * ratio) * (210 / 297);
				this.$store.state.qrImageSize.width = this.test.width;
				this.$store.state.qrImageSize.height = this.test.height;

			} else {
				this.$store.state.qrImageSize.height = this.test.height;
				this.$store.state.qrImageSize.width = this.test.width;
				//this.test.width = this.$store.state.qrImageSize.width;
			}

		},
		scaleImg() {
			if (!(this.test.width === '' || this.test.width === 0)) {

				if (Object.keys(this.test.qrImages).length > 0
					&& this.activeTemplate
					&& !this.activeTemplate.background
					&& this.test.width === 0) {

					let ratio = this.test.width / this.$store.state.qrImageSize.width;
					this.$store.state.qrImageSize.width = this.test.width;
					this.$store.state.qrImageSize.height = this.test.height * ratio * (210 / 297);

					this.getImageSize()
				} else {
					let ratio = this.test.width / this.$store.state.qrImageSize.width;

					if (this.$store.state.allQrTemplateDeleted) {
						this.test.height = (this.$store.state.qrImageSize.height * ratio) * (210 / 297);
					} else {
						this.test.height = (this.$store.state.qrImageSize.height * ratio);
					}
				}
			}
		},
		addQrImage(data) {
			this.qrBGImgUrl = Object.values(data)[0].url;
			this.test.qrImages = {...this.test.qrImages, ...data};

			if (this.activeTemplate
				&& !this.activeTemplate.background
			) {
				this.getImageSize();
			}
			this.showAddFileDialog = false
		},
		deleteImage(data) {
			delete this.test.qrImages[data];
			if (Object.keys(this.test.qrImages).length === 0) {
				this.test.width = 0
				this.test.height = 0
			}

			if (Object.keys(this.test.qrImages).length === 0) {
				this.$store.state.allQrTemplateDeleted = true;
			}

			if (Object.keys(this.test.qrImages).length === 1){
				this.qrBGImgUrl = Object.values(this.test.qrImages)[0].url;
			}
		},
		displayAddQrImage() {
			this.$store.state.displayAddQrImage = true;
		},
		//Basics
		close() {
			this.$store.state.allQrTemplateDeleted = false;

			this.widthInvalidQrImg = false;
			this.widthInvalid = false;
			this.fontError = false;

			//this.initData();
			this.updateSelectedName('1');

			this.$store.state.displayAddQrText = false;
			this.$store.state.displayAddQrImage = false;
			this.$store.state.displayEditQRTemplate = false;
			this.addLink = false;
			this.linkText = 'XXXXXXX';
		},

		async update() {
			let hasErrors = this.validate();
			if (!hasErrors) {
				if (this.test.qrImages !== {}) {
					this.activeTemplate.background = {}
					this.activeTemplate.background.xCoordinate = parseFloat(this.test.xCoord);
					this.activeTemplate.background.yCoordinate = parseFloat(this.test.yCoord);
					this.activeTemplate.background.width = parseFloat(parseFloat(this.test.width).toFixed(2));
					this.activeTemplate.background.height = parseFloat(this.test.height);
					this.activeTemplate.background.bucketName = this.$store.state.activeSG.storageConfig.bucketConfig.branding;
					this.activeTemplate.background.location = {}
				}

				for (const item in this.test.qrImages) {
					this.activeTemplate.background.location[item] = {
						fileName: this.test.qrImages[item].img,
						url: this.test.qrImages[item].url
					}
				}

				if (Object.keys(this.activeTemplate.background.location).length === 0) {
					delete this.activeTemplate.background;
				}

				this.activeTemplate.xCoordinate = parseFloat(this.activeTemplate.xCoordinate);
				this.activeTemplate.yCoordinate = parseFloat(this.activeTemplate.yCoordinate);

				if (this.activeTemplate.overlayMode !== "OFFSET") {
					delete this.activeTemplate.offset;
				}

				if (this.addLink) {
					delete this.activeTemplate.documentParameter.width
					this.activeTemplate.documentParameter.xCoordinate = parseFloat(this.activeTemplate.documentParameter.xCoordinate);
					this.activeTemplate.documentParameter.yCoordinate = parseFloat(this.activeTemplate.documentParameter.yCoordinate);
					this.activeTemplate.documentParameter.fontSize = parseFloat(this.activeTemplate.documentParameter.fontSize);

					if ((this.activeTemplate.documentParameter.font === undefined) || (Object.keys(this.activeTemplate.documentParameter.font)).length === 0) {
						delete this.activeTemplate.documentParameter.font;
					}
				} else {
					delete this.activeTemplate.documentParameter
				}
				let requestData = {
					qr: []
				}

				let requestDataArray = []
				this.qrTemplatesData.forEach((template) => {
					if (template.name === this.selectedTemplateName) {
						requestDataArray.push(this.activeTemplate);
					} else {
						requestDataArray.push(template);
					}
				})

				requestData.qr = requestDataArray;

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.supplierGroup.supplierGroupId,
						edit: "qr-template",
						body: requestData,
					};

					await this.$store.dispatch("EditSupplierGroup", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Suppliergroup",
							text: "QR template successfully edited",
						});
					}
				}

				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "qr-template",
						body: requestData,
					};

					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "QR template successfully edited",
						});
					}
				}
				this.close();
			}
		},
		showConfirmDialog() {
			this.showConfirm = true;
		},
		async delTemp(data) {
			if (data === "Yes") {
				let requestData = {
					qr: []
				}

				let requestDataArray = []
				this.qrTemplatesData.forEach((template) => {
					if (template.name !== this.selectedTemplateName) {
						requestDataArray.push(template);
					}
				});

				if (Object.keys(requestDataArray).length === 0) {
					requestData = {}
				} else {
					requestData.qr = requestDataArray;
				}

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.supplierGroup.supplierGroupId,
						edit: "qr-template",
						body: requestData,
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
				}
				if (this.isFor === "legalEntity") {

					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "qr-template",
						body: requestData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
				}
				this.close();
			}
			this.showConfirm = false;
		},
		validate() {
			this.widthInvalid = false;
			this.selectedOverlayInvalid = false;
			let hasErrors = false;
			if (this.activeTemplate.overlayMode === "") {
				this.selectedOverlayInvalid = true;
				hasErrors = true;
			}

			if (this.activeTemplate.width + parseFloat((this.activeTemplate.xCoordinate)) > 100) {
				this.widthInvalid = true;
				this.widthErrorText = "Width may not scale out of boundary";
				hasErrors = true;
			}
			if (this.activeTemplate.docTypes.length === 0) {
				this.docTypesError = true;
				hasErrors = true;
			}
			if (this.activeTemplate.reason === 'No template reason' || this.activeTemplate.reason === '') {
				this.reasonError = true;
				hasErrors = true;
			}

			if (this.test.width > 100) {
				this.widthInvalidQrImg = true;
				this.widthErrorTextQrImg = "Width may not scale out of boundary";
				hasErrors = true;
			}
			if (this.activeTemplate.width === 0) {
				this.widthInvalid = true;
				hasErrors = true;
				this.widthErrorText = "Width may not be 0";
			}

			if (this.addLink){
				if (this.activeTemplate.documentParameter.font.fileName === ''){
					this.fontError = true;
					hasErrors = true;
				}

			}
			return hasErrors;
		},
	},
};
</script>

<style scoped>
.makeSquare:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.make-transparent {
	opacity: 0.7;
	z-index: 50;
}

.label-style {
	font-weight: 700;
}

.color-mark {
	width: 30px;
}

.label-with-mark {
	display: grid;
	grid-template-columns: 20px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}

.label-with-mark > div {
	width: 15px;
	height: 15px;
	align-self: center;
}

.bg-red {
	background-color: red;
}

.bg-blue {
	background-color: #a76edb;
}

.bg-green {
	background-color: rgb(145, 199, 20);
}

.grid-div {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-self: start;
	align-self: start;
}

.check-link-text {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
}

.check-link-text > span, .check-link-text > .check-link-input {
	grid-column: 1/2;
	grid-row: 1/2;
}

.check-link-text > .check-link-input {
	z-index: 100;
}

.check-link-text > span {
	opacity: 0;
	z-index: 2;
	justify-self: start;
}

.qRImage{
	background-repeat: no-repeat;
	background-position: top left;
	background-size: contain;
	background-color: #91c714;
}
.z-150{
	z-index: 51;
}

.full-size{
	width: 100%;
	height: auto;
}
</style>