import axios from "axios"
import store from "@/store/index"

const qs = require('qs')
const env = window.environment

axios.interceptors.request.use(async (config) => {
	if (!config.url.includes("protocol/openid-connect/token")
		&& !config.url.includes("/current")
		&& !config.url.includes("/scheduler/jobs")
		&& (!config.url.includes("/admin/supplier-groups") || config.url.includes("/admin/supplier-groups/"))
		&& !config.url.includes("/favicon")) {
		await store.dispatch("updateToken")
		if (store.state.tokenIsValid === true){
			config.headers.Authorization = "bearer " + store.state.token
			return config
		}
	} else return config
})
const actions = {
	async updateToken(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.state.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.state.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		)
			.then(res => {
				context.commit('setToken', res.data.access_token)
				context.commit('setRefreshToken', res.data.refresh_token)
				localStorage.setItem('authToken',  res.data.refresh_token )
				context.state.tokenIsValid = true
			})
			.catch(() => {
				context.state.tokenIsValid = false
				localStorage.setItem('didLogOut', "yes")
			})
	},
	getCurrentUser(context) {
		axios.request({
			url: "api/users/user/current",
			method: "get",
			baseURL: context.state.baseUrl,
			headers: {
				Authorization: `Bearer ${context.state.token}`,
			},
		})
			.then((response) => {
				context.state.currentUser = response.data.data
				context.state.responseStatus = 200
			})
			.catch((exception) => {
				context.state.responseStatus = 400
				console.error("fetch-CurrentUser-error: ", exception)
			})
	},
	async setFavIcon(context) {
		await axios.get(context.state.baseUrl + context.state.API.getFavIcon, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			},
		}).then(response => {
			document.getElementById('favicon').setAttribute('href', response.data)
		})
	},
	reprocessXml(context, payload) {
		let url = context.state.baseUrl + context.state.API.reprocessXml
		return axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.state.token,
				}
			}
		).then(() => {
			context.commit("toggleAlert", {
				title: "Reprocess XML",
				text: "XML successfully reprocessed",
			})
		}).catch(() => context.commit('displayErrorAlert', true))
	}
}
export default actions
