<template>

    <pop-up-window
          :show="this.$store.state.displayModifyPeppolDocument"
          :showClose="false"
          @close="close"
          class="doc-statuses-popup"
          fixed
          :positionFixed="true"
    >
        <template #header>{{ header }}</template>
        <template #content>
            <div class=" mb-2">
                <h2 class="font-bold">Peppol identifier : {{ peppolIdentifier }}</h2>

            </div>
            <div class="input-outer mb-2">
                <p class="font-bold">SMP provider:</p>

                <Dropdown

                      @update-value="updateSmpProvider"
                      :data="smpProviders"
                      :selected=selectedSmpProvider

                />
            </div>
            <div class="input-outer mb-2" v-if="displayDocTypeIdentifiers">
                <p class="font-bold">Document type:</p>
                <Dropdown


                      @update-value="updateDocTypeIdentifier"
                      :data="documentTypeIdentifiers"
                      :selected=selectedDocumentTypeIdentifier

                />
            </div>


            <div class="grid grid-rows-1 mt-2 justify-items-end">
                <div>


                    <button class="btn shadow-md mr-2" @click="openConfWindow">{{ modifyButtonText }}</button>
                    <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </pop-up-window>
    <ConfirmDialog @close="checkAnswer" :show="showConfirm"/>

</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Dropdown from "../Dropdown.vue";
import ConfirmDialog from "../ConfirmDialog.vue"

export default {
    name: "ModifyPeppolItem",
    components: {
        PopUpWindow,
        Dropdown,
        ConfirmDialog,

    },
    props: {
        peppolIdentifier: {
            required: true,
            type: String,

        },
        header: {
            required: true,
            type: String,
        },
        modifyItem: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            smpProviders: this.$store.state.smpProviders,
            documentTypeIdentifiers: this.$store.state.documentTypeIdentifiers,
            selectedSmpProvider: this.$store.state.smpProviders[0].name,
            selectedDocumentTypeIdentifier: this.$store.state.documentTypeIdentifiers[0].name,
            showConfirm: false,

        }
    },
    computed: {
        modifyButtonText(){
            if (this.modifyItem === 'createDocument'){
                return "Create"
            }
          return "Delete"
        },
        displayDocTypeIdentifiers() {
            if (this.modifyItem === 'deleteDocument' || this.modifyItem === 'createDocument') {
                return true
            }
            return false
        }
    },
    methods: {
        modifyPeppolItem() {
            let formData = {
                identifier: this.peppolIdentifier,
                smpProvider: this.selectedSmpProvider,
                documentTypeIdentifier: this.selectedDocumentTypeIdentifier
            }
            if (this.modifyItem === 'deleteDocument') {
                this.$store.dispatch("peppol/deletePeppolDocument", formData);
            }
            if (this.modifyItem === 'deleteParticipant') {
                delete formData.documentTypeIdentifier;
                this.$store.dispatch("peppol/deletePeppolParticipant", formData);
            }
            if (this.modifyItem === 'createDocument'){
				try {
					this.$store.dispatch("peppol/createPeppolDocument", formData);
				} catch (error) {
					this.$store.commit('displayErrorAlertText', "Error: " + error.message, {root: true})
					this.$store.commit('displayErrorAlert', true, {root: true})
				}
            }

        },
        checkAnswer(data) {
            if (data === "Yes") {
                this.modifyPeppolItem();
                this.close()

            } else {
                this.close()
            }
        },
        updateDocTypeIdentifier(data) {
            this.selectedDocumentTypeIdentifier = this.documentTypeIdentifiers.find((item) => (item.id == data)).name;
        },
        updateSmpProvider(data) {
            this.selectedSmpProvider = this.smpProviders.find((item) => (item.id == data)).name;
        },
        openConfWindow() {
            this.showConfirm = true;
        },
        close() {
            this.$store.state.displayModifyPeppolDocument = false;
            this.selectedSmpProvider = this.$store.state.smpProviders[0].name;
            this.selectedDocumentTypeIdentifier = this.$store.state.documentTypeIdentifiers[0].name;
            this.showConfirm = false;
        }
    }

}
</script>

<style scoped>
.input-outer {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 120px calc(100% - 120px);
    align-items: center;
    width: 50%;
}

</style>
