import axios from "axios"

export default {
	async uploadFile(context, payload) {
		await axios.request({
			url: "api/admin/supplier-groups/upload/files",
			method: "post",
			baseURL: context.rootState.baseUrl,
			data: payload,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then((response) => {
				context.commit('fileUploaded', true, {root: true})
				context.rootState.responseStatus = response.status
				context.rootState.responseStatus = 200
				context.rootState.uploadedFileUrl = response.data.data[0]
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("uploadFile-error: ", exception)
			})
	},
	uploadFileSftpMount(context, payload) {
		let url = context.rootState.baseUrl + context.rootState.API.uploadFile
		return axios.post(url, payload.file, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("setSftpMountUploaded", {type: payload.type, value: true}, {root: true}))
	},
	async getProxyFile(context, payload) {
		await axios.request({
			url: "api/admin/supplier-groups/" + payload.supplierGroup + "/branding/" + payload.file,
			method: "get",
			baseURL: context.rootState.baseUrl,
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		})
			.then((response) => {
				context.commit('setTempFile', response.data.data, {root: true})
				context.rootState.responseStatus = 200
			})
			.catch((exception) => {
				context.rootState.responseStatus = 400
				console.error("getProxyFile-error: ", exception)
			})
	},
}