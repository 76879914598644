<template>
	<pop-up-window
		:show="this.$store.state.displayAddEdi"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add EDI</template>
		<template #content>
			<div class="flex flex-row">
				<div class="flex flex-col mr-12 w-1/2">
					<div class="mb-3">
						<label for="ediSendingProvider" class="font-bold form-label">Receiving Provider:</label>
						<Dropdown
							id="addEdiReceivingProvider"
							@update-value="updateSelectedReceivingProvider"
							:data="ediReceivingProvider"
							:selected="receivingProvider"
						/>
					</div>

					<span v-if="receivingInvalid" class="text-primary-3">Receiving provider is required</span>
					<div class="mb-3">
						<label for="ediSendingProvider" class="font-bold form-label">Sending Provider:</label>
						<Dropdown
							id="ediSendingProvider"
							@update-value="updateSelectedSendingProvider"
							:data="ediData"
							:selected="selectedSendingProvider"
						/>
					</div>
				</div>
				<div class="flex flex-col w-1/2">
					<div class="mb-3 flex">
						<label for="addEdiResendBySupplier" class="font-bold form-label mb-0 mr-2">
							Resend allowed by supplier:
						</label>
						<ToggleSlide
							class="w-min"
							id="addEdiResendBySupplier"
							@update-value="changeResendBool"
							:bool="resendAllowedBySupplier"
						/>
					</div>
					<div class="mb-3 flex">
						<label for="addEdiFallbackToBW" class="font-bold form-label mb-0 mr-2">Fallback to BW:</label>
						<ToggleSlide
							class="w-min"
							id="addEdiFallbackToBW"
							@update-value="changeFallbackToBWBool"
							:bool="fallbackToBW"
						/>
					</div>
					<div class="mb-3 flex">
						<label for="addEmbedToBW" class="font-bold form-label mb-0 mr-2">Embed to BW:</label>
						<ToggleSlide
							class="w-min"
							id="addEmbedToBW"
							@update-value="changeEmbedToBWBool"
							:bool="embedToBW"
						/>
					</div>
					<div class="mb-3 flex">
						<label for="addEdiReceiverRegNr" class="font-bold form-label mb-0 mr-2">
							Sender Registration number check:
						</label>
						<ToggleSlide
							class="w-min"
							id="addEdiReceiverRegNr"
							@update-value="changeReceiverRegNrBool"
							:bool="receiverRegNr"
						/>
					</div>
				</div>
			</div>

			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="add">Add</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Dropdown from "../Dropdown.vue"
import ToggleSlide from "../ToggleSlide.vue"

export default {
	name: "AddEdi",
	components: {
		PopUpWindow,
		Dropdown,
		ToggleSlide,
	},
	props: {
		ediData: Array,
		ediReceivingProvider: Array
	},
	data() {
		return {
			receivingProvider: this.ediReceivingProvider[0].name,
			selectedSendingProvider: "ADVALVAS",
			receivingInvalid: false,
			resendAllowedBySupplier: false,
			fallbackToBW: false,
			embedToBW: false,
			receiverRegNr: false,
		}
	},
	emits: ["add-edi"],
	methods: {
		updateSelectedSendingProvider(data) {
			if (data) this.selectedSendingProvider = this.ediData[data].name
		},
		updateSelectedReceivingProvider(data) {
			if (data[0] !== -1) this.receivingProvider = this.ediReceivingProvider[data].name
			 else this.receivingProvider = this.ediReceivingProvider[0].name
		},
		changeResendBool(data) {
			this.resendAllowedBySupplier = data
		},
		changeFallbackToBWBool(data) {
			this.fallbackToBW = data
		},
		changeEmbedToBWBool(data) {
			this.embedToBW = data
		},
		changeReceiverRegNrBool(data) {
			this.receiverRegNr = data
		},
		close() {
			this.receivingProvider = this.ediReceivingProvider[0].name
			this.selectedSendingProvider = "ADVALVAS"
			this.resendAllowedBySupplier = false
			this.fallbackToBW = false;
			this.embedToBW = false;
			this.receiverRegNr = false;
			this.receivingInvalid = false;
			this.$store.state.displayAddEdi = false;
		},
		add() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				let payload = {
					name: this.receivingProvider,
					provider: this.selectedSendingProvider,
					resendAllowedBySupplier: this.resendAllowedBySupplier,
					fallbackToBW: this.fallbackToBW,
					embedToBW: this.embedToBW,
					receiverRegNrCheck: this.receiverRegNr
				}

				this.$emit("add-edi", payload)
				this.close()
			}
		},
		validate() {
			let hasErrors = false
			this.receivingInvalid = false
			if (this.receivingProvider === "") {
				this.receivingInvalid = true
				hasErrors = true
			}
			return hasErrors
		}
	}
}
</script>