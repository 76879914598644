<template>
	<pop-up-window
		:show="showPopUp"
		:showClose="false"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>DNS records</template>
		<template #content>
			<div>
				<div class="flex flex-row">
					<p class="mb-4">DNS records of <span class="font-bold">{{ senderDomain }}</span>:</p>
					<span v-if="this.$store.state.valueCopied" class="ml-2 font-bold trans text-theme-1">Copied</span>
				</div>

				<div ref="dnsRecordsTable"></div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md bg-theme-1 text-white mr-2 mt-2" @click="$emit('close')">
						Close
					</button>
				</div>
			</div>
		</template>

	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUp.vue"
import Field from "./Field.vue"
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import store from "@/store"

export default {
	components: {PopUpWindow, Field},
	emits: ['close'],
	props: {
		senderDomain: String,
		showPopUp: Boolean
	},
	data() {
		return {
			dnsRecords: null,
			table: null,
			tableData: null,
			isResizing: false,
			copiedBool: false,
			columns: [
				{
					title: "",
					field: "verified",
					hozAlign: "center",
					width: '10%',
					headerSort: false,
					formatter(cell) {
						if (cell.getData().verified) {
							return `<div  class="font-medium whitespace-nowrap salary billing">
                                  <span class="material-icons-outlined" style="color: #91c714">check_circle</span>
                              </div>`
						} else {
							return `<div  class="font-medium whitespace-nowrap salary billing">
                                   <span class="material-icons-outlined" style="color: #d32929">highlight_off</span>
                              </div>`
						}
					}
				},
				{
					title: "HostName",
					field: "hostName",
					width: '30%',
					headerSort: false,
					cellClick: function (e, cell) {
						navigator.clipboard.writeText(cell._cell.value)
						store.state.valueCopied = true
						setTimeout(() => {
							store.state.valueCopied = false
						}, 750)
					}
				},
				{
					title: "Type",
					field: "type",
					hozAlign: "center",
					width: '10%',
					headerSort: false,
				},
				{
					title: "Add this value",
					field: "addThisValue",
					headerSort: false,
					cellClick: function (e, cell) {
						navigator.clipboard.writeText(cell._cell.value)
						store.state.valueCopied = true
						setTimeout(() => {
							store.state.valueCopied = false
						}, 750)
					}
				}
			]
		}
	},
	async mounted() {
		if (this.senderDomain) {
			await this.getDnsSettings()
		}
	},
	methods: {
		async getDnsSettings() {
			let resp = await this.$store.dispatch('globalActions/getDnsRecordsPostmark', this.senderDomain)
			if (resp.status === 404) {
				let respCreate = await this.$store.dispatch("globalActions/createDnsRecordsPostmark", this.senderDomain)
				if (respCreate.status === 200) {
					await this.$store.dispatch('globalActions/syncDnsRecordsPostmark')
					resp = await this.$store.dispatch('globalActions/getDnsRecordsPostmark', this.senderDomain)
					if (resp.status !== 200) {
						this.$store.commit('displayErrorAlert', true)
						console.error(resp)
						return
					}
				} else if (resp.status !== 404 && !resp.status.toString().startsWith('2')) {
					this.$store.commit('displayErrorAlert', true)
					console.error(resp)
					return
				}
			}
			if (resp.status === 200) {
				this.dnsRecords = resp.data.data
				this.tableData = [
					{
						hostName: this.dnsRecords.dkimHost ? this.dnsRecords.dkimHost : this.dnsRecords.dkimPendingHost,
						type: 'TXT',
						addThisValue: this.dnsRecords.dkimTextValue ? this.dnsRecords.dkimTextValue : this.dnsRecords.dkimPendingTextValue,
						verified: this.dnsRecords.dkimVerified
					},
					{
						hostName: this.dnsRecords.returnPathDomain,
						type: 'CNAME',
						addThisValue: this.dnsRecords.returnPathDomainCNAMEValue,
						verified: this.dnsRecords.returnPathDomainVerified
					}
				]
				await this.initTabulator()
			}
		},
		async initTabulator() {
			if (this.senderDomain) {
				this.table = await new Tabulator(this.$refs['dnsRecordsTable'], {
					headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
					layout: "fitColumns",
					columnDefaults: {
						resizable: false,
					},
					autoResize: true,
					locale: false,

					resizableColumnFit: true,

					data: this.tableData,
					columns: this.columns
				})
				window.addEventListener('resize', () => {
					if (this.isResizing === false && this.table !== undefined) {
						this.isResizing = true
						this.table.setColumns(this.columns)
						this.isResizing = false
					}
				})
			}
		}
	}

}
</script>

<style scoped>
.not-verified {
	color: #d32929;
}

.verified {
	color: #91c714;
}
</style>