<template>
	<pop-up-window
		:show="show"
		:showClose="false"
		@close="close"
		fixed
	>
		<template #header>Advanced settings {{createdLeEntityNumber}}</template>
		<template #content>
			<div>
				<set-reporting
					:colors="colorSetButton"
					:created-le="createdLeEntityNumber"
				/>
				<set-peppol
					:colors="colorSetButton"
					:created-le-entity-number="createdLeEntityNumber"
					:created-le-name="createdLeName"
				/>
				<set-mailing
					:colors="colorSetButton"
					:created-le-entity-number="createdLeEntityNumber"
					:created-le-name="createdLeName"
				/>
				<div class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md bg-theme-1 text-white mr-2 mt-2" @click="$emit('close')">
							Close
						</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "@/components/PopUp.vue";
import Field from "@/components/Field.vue";
import ToggleSlide from "@/components/ToggleSlide.vue";
import SetReporting from "@/components/AddLegalEntity/setReporting.vue";
import SetMailing from "@/components/AddLegalEntity/setMailing.vue";
import SetPeppol from "@/components/AddLegalEntity/setPeppol.vue";

export default {
	name: "AddAdvancedLe",
	emits: ['close'],
	components: {SetPeppol, SetMailing, SetReporting, ToggleSlide, Field, PopUpWindow},
	props: {
		show: Boolean,
		createdLeEntityNumber: String,
		createdLeName: String
	},
	data() {
		return {
			peppol: false,
			colorSetButton: {blue: '#291f8d', success: '#91c714', error: '#d32929'}
		}
	}
}
</script>