import {createApp, h} from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Keycloak from 'keycloak-js';

const env = window.environment;
import './assets/sass/app.scss'
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VuePdf from 'vue3-pdfjs'
import {
    faPowerOff,
    faTrashCan,
    faPencil,
    faList,
    faGlobe,
    faFileExport,
    faLayerGroup,
    faChevronUp,
    faUpload,
    faGears,
    faCopy,
    faAtom,
    faListCheck,
    faFlask,
    faCalendar,
    faFile

} from '@fortawesome/free-solid-svg-icons'

library.add(
      faPowerOff,
      faTrashCan,
      faPencil,
      faList,
      faGlobe,
      faFileExport,
      faLayerGroup,
      faChevronUp,
      faUpload,
      faGears,
      faCopy,
      faAtom,
      faListCheck,
      faFlask,
      faCalendar,
      faFile
);


let initOptions = {
    url: env.data.keycloakUrl,
    realm: env.data.keycloakRealm,
    clientId: env.data.keycloakClient,
    onLoad: 'check-sso'
}

let keycloak = new Keycloak(initOptions);

keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
    if (!auth){
        keycloak.login()
    }

    const app =
          createApp({
              data: () => ({
                  keycloak: keycloak,
                  token: keycloak.token,
              }),
              render: () => (
                    h(App, {props: {keycloak: keycloak}})

              )
          })
                .use(store)
                .use(VuePdf)
                .use(router)
                .component("font-awesome-icon", FontAwesomeIcon)
                .mount("#app");
    let bffUrl='';
    if (env.data.bffUrl) {
        bffUrl = (env.data.bffUrl).replace("-vue", "")
    }

    if (window.location.hostname === 'localhost'){
        store.state.baseUrl = 'https://staging-admin.nymus.eu/'
        store.state.baseUrlNymusX = 'https://test-api.nymus.eu/'
    } else {
        store.state.baseUrl = '/'
        store.state.baseUrlNymusX = 'https://api.nymus.eu/'
    }
/*
    if (env.data.bffUrl === undefined) {
        store.state.baseUrl = 'https://staging-adminvue.optipost.eu'
    } else {
        store.state.baseUrl = 'https://' + bffUrl
    }
*/
    router.app = app;

    // setInterval(() => {
    //     keycloak.updateToken(700).then(function (refreshed) {
    //         if (refreshed) {
    //             console.log('Token was successfully refreshed');
    //             app.keycloak = keycloak;
    //             app.token = keycloak.token;
    //             store.state.token = keycloak.token
    //
    //         } else {
    //             console.log('Token is still valid');
    //
    //         }
    //     }).catch(function () {
    //         console.log('Failed to refresh the token, or the session has expired');
    //     });
    // }, 6000);
});
export default keycloak;
