import axios from "axios"

export default {
	async getSmpParticipants(context, payload) {
		try {
			let url = context.rootState.baseUrl + context.rootState.API.smpParticipants
			let res = await axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})

			context.commit('setSmpParticipants', res.data.data.information, {root: true})
		} catch (error) {
			context.commit('displayErrorAlert', true, {root: true})
		}
	},

	addPeppolIdentifier(context, payload) {
		let url = context.rootState.baseUrl + context.rootState.API.addPeppolIdentifier.replace(':supplierGroupId', payload.sgId).replace(':legalEntityNumber', payload.leId)
		return axios.post(url, payload.peppolParticipants, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
					'Content-Type': "application/json; charset=utf8"
				}
			}
		).then((response) => {
			let pl = {
				data: response.data.data,
				sgId: payload.sgId
			}
			context.commit('setUpdatedLegalEntity', pl, {root: true})

			context.commit("toggleAlert", {
				title: "Update Peppol Identifiers",
				text: "New Peppol Identifiers successfully updated",
			}, {root: true})
			context.commit('peppolItemChanged', true, {root: true})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	},

	async createPeppolParticipant(context, payload) {
		try {
			let url = context.rootState.baseUrl + context.rootState.API.createPeppolParticipant
			let res = await axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})

			if (res.data.data?.success) {
				context.commit('peppolCreateDocumentDisplay', true, {root: true})
				context.commit("toggleAlert", {
					title: "Create new Peppol participant",
					text: "New participant successfully created",
				}, {root: true})
			} else {
				if (res.data.data?.message) throw new Error(res.data.data.message)
				else throw new Error("Something went wrong")
			}

			return res
		} catch (error) {
			context.commit('peppolCreateDocumentDisplay', false)
			throw new Error(error.message)
		}
	},
	deletePeppolParticipant(context, payload) {
		let url = context.rootState.baseUrl + context.rootState.API.deletePeppolParticipant
		return axios.delete(url,
			{
				data: {
					identifier: payload.identifier,
					smpProvider: payload.smpProvider,
				},
				headers: {
					'Content-Type': "application/json",
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit("toggleAlert", {
				title: "Delete Peppol document",
				text: "Document successfully Deleted",
			}, {root: true})
			context.commit('peppolItemChanged', true, {root: true})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	},

	async createPeppolDocument(context, payload) {
		try {
			let url = context.rootState.baseUrl + context.rootState.API.createPeppolDocument
			let res = await axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})

			if (res.data.data.success) {
				context.commit("toggleAlert", {
					title: "Create new Peppol document",
					text: "New document successfully created",
				}, {root: true})
				context.commit("peppolDocumentCreated", true, {root: true})
				context.commit('peppolItemChanged', true, {root: true})
			} else {
				if(res.data.data?.message) throw new Error(res.data.data?.message)
				else throw new Error("Something went wrong")
			}
		} catch (error) {
			throw new Error(error.message)
		}
	},
	deletePeppolDocument(context, payload) {
		let url = context.rootState.baseUrl + context.rootState.API.deletePeppolDocument
		return axios.delete(url, {
				data: {
					identifier: payload.identifier,
					smpProvider: payload.smpProvider,
					documentTypeIdentifier: payload.documentTypeIdentifier

				},
				headers: {
					'Content-Type': "application/json",
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
			if (res.data.data.success) {
				context.commit("toggleAlert", {
					title: "Delete Peppol document",
					text: "Document successfully Deleted",
				}, {root: true})
				context.commit('peppolItemChanged', true, {root: true})
				context.commit("peppolDocumentDeleted", true, {root: true})

			} else {
				context.commit('displayErrorAlertText', "Error: " + res.data.data.message, {root: true})
				context.commit('displayErrorAlert', true, {root: true})
			}
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	},

	retrievePeppolInfo(context, payload) {
		let url = context.rootState.baseUrl + context.rootState.API.retrievePeppolInfo.replace(':identifier', payload)

		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(res => {
			context.commit('setPeppolRetrievedData', {
				information: res.data.data.information,
				identifier: payload
			}, {root: true})
			context.commit('peppolIdentifiersDisplay', true, {root: true})
			context.commit('peppolIdentifiersResults', {field: "participantFound", value: true}, {root: true})
			context.commit('setLoader', false, {root: true})
		})
			.catch(() => {
				context.commit('peppolIdentifiersDisplay', true, {root: true})
				context.commit('peppolIdentifiersResults', {field: "participantNotFound", value: true}, {root: true})
				context.commit('setLoader', false, {root: true})
			})
	},
	async getHermesMigrationKey(context, payload) {
		try {
			const url = context.rootState.baseUrl + context.rootState.API.getHermesMigrationKey.replace(":identifier", payload)
			let res = await axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})

			return res.data.data.keyOrError
		} catch (error) {
			throw new Error(error.message)
		}
	},
	async migrateSmp(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.migrateSmp
		try {
			await axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
		} catch (error) {
			throw new Error(error.message)
		}
	},
	triggerPeppolIngestion(context) {
		let url = context.rootState.baseUrl + context.rootState.API.triggerPeppolIngestion
		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit("toggleAlert", {
				title: "Peppol ingestion",
				text: "Peppol ingestion successfully triggered",
			}, {root: true})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	},

	peppolConfig(context, payload) {
		let url
		if (payload.legalEntityNumber) {
			url = context.rootState.baseUrl + context.rootState.API.peppolConfigLe.replace(":supplierGroupId", payload.supplierGroupId).replace(':legalEntityNumber', payload.legalEntityNumber)
		} else {
			url = context.rootState.baseUrl + context.rootState.API.peppolConfigSg.replace(":supplierGroupId", payload.supplierGroupId)
		}

		return axios.post(url,
			payload.requestData,
			{
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
			.then(res => {
				context.dispatch("fetchSupplierGroups", null, {root: true})

				if (payload.legalEntityNumber) context.commit('setActiveLe', res.data.data, {root: true})
				else context.commit('setActiveSg', res.data.data, {root: true})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
	}
}