<template>
	<div class="form-container">
		<div class="form-item ">
			<p class="mb-1 font-bold">Email:</p>
			<input
				id='email'
				type='email'
				v-model="modelValue.email"
				@input="changeFormData"
				@focusout="checkEmail"
				@focusin="modelValue.isValid = null"
				class="form-control text-popup"
			/>
		</div>
		<div class="form-item ml-2.5">
			<p class="mb-1 font-bold">Language:</p>
			<Dropdown
				id="externalPortalLanguage"
				@update-value="updateSelectedLanguage"
				:data="languages"
				:selected="modelValue.language"
			/>
		</div>
	</div>

</template>

<script>
import VueSelect from "vue3-select"
import Dropdown from "@/components/Dropdown.vue";
import langCodes from "@/langCodes";

export default {
	components: {Dropdown, VueSelect},
	model: {
		prop: 'modelValue',
		event: 'change'
	},
	props: ['modelValue'],
	data() {
		return {
			formData: {
				email: '',
				language: langCodes.find(l => l.name === 'NL').name,
				isValid: null
			}
		}
	},
	computed: {
		languages() {
			return langCodes
		}
	},
	methods: {
		checkEmail(){
			this.modelValue.isValid = this.validateEmail(this.modelValue.email)
		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			return re.test(email);
		},
		updateSelectedLanguage(value) {
			if (typeof value !== "object") {
				this.modelValue.language = this.languages[value].name
				this.$emit('update:modelValue', this.modelValue)
			}
		},
		changeFormData(e) {
			this.modelValue[e.target.id] = e.target.value
			this.$emit('update:modelValue', this.modelValue)
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 0px);
	grid-template-rows: 50% 50%;
	grid-auto-flow: row;
	padding-bottom: 0;
	grid-column-gap: 0;
}

@media only screen and (max-width: 600px) {
	.form-container {
		grid-template-columns: 100%;
		grid-template-rows: 30% 30%;
	}

	.form-item:first-child {
		padding-bottom: 0;
	}
}
</style>